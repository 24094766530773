import gql from "graphql-tag";

const updateContractEquipment = gql`
  mutation UpdateContractEquipmentData(
    $contractEquipmentId: ID!
    $Input: UpdateContractEquipmentInput!
  ) {
    updateContractEquipment(
      contractEquipmentId: $contractEquipmentId
      input: $Input
    ) {
      id
      quantity
      description
      equipmentFrequency {
        id
      }
    }
  }
`;

export default updateContractEquipment;
