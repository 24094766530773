import React, { useState } from "react";
import styled from "styled-components";
import { PageComponentProps } from "../../interfaces/common";
import { parseGqlErrors, pxToEm } from "../../util/ts/helpers";
import { capitalizeString, valueExists } from "../../util/helpers";
import { useTranslation } from "react-i18next";
import { ContractEquipmentData_equipmentFrequencies } from "../../interfaces/graphql/ContractEquipmentData";
import InfoTile from "../organisms/InfoTile";
import Form from "../_functional/Form";
import EditEquipmentFields from "./EditEquipmentFields";
import { UpdateContractEquipmentData_updateContractEquipment } from "../../interfaces/graphql/UpdateContractEquipmentData";
import { RemoveContractEquipmentData_removeContractEquipment } from "../../interfaces/graphql/RemoveContractEquipmentData";
import updateContractEquipment from "../../graphql/mutations/updateContractEquipment";
import removeContractEquipment from "../../graphql/mutations/removeContractEquipment";
import { FormikHelpers, FormikValues } from "formik";
import constants from "../_globalStyles/constants";
import { useMutation } from "@apollo/react-hooks";
import Button from "../atoms/Button";

const EquipmentWrap = styled.div`
  .fields-wrap {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 0 calc(50% - 0.5ch);
      + div {
        margin-left: 1ch;
      }
      &:last-child {
        flex: 1 0 100%;
        margin-left: 0;
      }
    }
  }

  .equipment-contract-tile {
    margin: 1ch 1ch 0;

    + .edit-equipment,
    + .action-confirmation {
      margin-top: -1.5em;
    }
  }
  .edit-equipment {
    margin: 0 1ch 0;
    padding: 1ch;
  }
  .error {
    margin: -1.5em 0 1.5em;
    color: ${constants.DANGER_COLOR};
    font-size: ${pxToEm(14, 16)}rem;
  }

  .description {
    flex: 1;
    width: 100%;
    margin-left: 0;
  }

  .action-confirmation {
    text-align: center;
    padding: 0 1ch 0.75em;
    margin: 0 1ch;
    background: ${constants.MAIN_LIGHT_COLOR};

    .message {
      color: ${constants.DANGER_COLOR};
      margin-bottom: 1ch;
    }

    button + button {
      margin-left: 1ch;
    }
  }
`;

interface EquipmentTileProps extends PageComponentProps {
  initialFrequency: string;
  initialAmount: string;
  name: string;
  frequencies: ContractEquipmentData_equipmentFrequencies[];
  equipmentId: string;
  frequencyId: string;
  amount: number;
  description: string;
  onEquipmentDelete: () => void;
}

const EquipmentTile: React.FunctionComponent<EquipmentTileProps> = props => {
  const [t] = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [error, setError] = useState<string | null>();

  const [state, setState] = useState({
    frequency: {
      id: props.frequencyId,
      value: t(`equipment.frequencies.${props.initialFrequency}`)
    },
    amount: props.initialAmount,
    description: props.description
  });

  const [editEquipment] = useMutation<
    UpdateContractEquipmentData_updateContractEquipment
  >(updateContractEquipment);

  const [removeEquipment] = useMutation<
    RemoveContractEquipmentData_removeContractEquipment
  >(removeContractEquipment, {
    variables: {
      contractEquipmentId: props.equipmentId
    }
  });

  const handleSubmit = (
    values: {
      frequency: { id: string; value: string };
      amount: string;
      description: string;
    },
    form: FormikHelpers<FormikValues>
  ): any => {
    setState({ ...values });
    editEquipment({
      variables: {
        contractEquipmentId: props.equipmentId,
        Input: {
          equipmentFrequencyId: values.frequency.id
            ? values.frequency.id
            : props.frequencyId,
          quantity: valueExists(values.amount)
            ? Number(values.amount)
            : props.amount,
          description: values.description
            ? values.description
            : props.description
        }
      }
    }).catch((err: any) => {
      form.setSubmitting(false);
      const error = err && parseGqlErrors(err);
      setError(error?.quantity);
    });
  };

  return (
    <EquipmentWrap>
      <InfoTile
        timeStamp="20012020"
        idPrefix="t-equipment"
        className="equipment-contract-tile"
        title={`${props.name}`}
        items={{
          [t("general.frequency")]: capitalizeString(
            t(`equipment.frequencies.${props.initialFrequency}`),
            true
          ),
          [t("general.amount")]: props.initialAmount,
          [t("general.description")]: props.description
        }}
        onEdit={() => {
          setEditMode(true);
          setDeleteMode(false);
        }}
        onDelete={() => {
          setDeleteMode(true);
          setEditMode(false);
        }}
        hideItemsOnEdit={!deleteMode && !editMode}
      />
      {editMode && !deleteMode && (
        <Form
          className="edit-equipment"
          idPrefix="t-equipment"
          timeStamp="08012020"
          initialValues={{ ...state }}
          onSubmit={(values, form): void => {
            handleSubmit(values, form);
          }}
          onCancel={(): void => setEditMode(false)}
          requiredValues={["frequency", "amount"]}
        >
          <EditEquipmentFields
            idPrefix="t-edit-equipment"
            timeStamp="20012020"
            amountFieldName="amount"
            frequencyFieldName="frequency"
            descriptionFieldName="description"
            initialValue={state.frequency.value}
            error={error}
            items={props.frequencies.map(item => {
              return {
                id: item.id,
                value: t(`equipment.frequencies.${item.name}`)
              };
            })}
          />
        </Form>
      )}
      {deleteMode && !editMode && (
        <div className="action-confirmation">
          <p className="cfl message">{`${t("schedules.remove_day")} ${
            props.name
          }?`}</p>
          <Button
            id="remove-visit-day-confirm"
            danger
            onClick={() => {
              removeEquipment().then(() => {
                props.onEquipmentDelete();
              });
            }}
          >
            {t("general.remove")}
          </Button>
          <Button
            danger
            secondary
            id="cancel-remove-day"
            color={constants.DANGER_COLOR}
            onClick={() => setDeleteMode(false)}
          >
            {t("general.cancel")}
          </Button>
        </div>
      )}
    </EquipmentWrap>
  );
};

export default EquipmentTile;
