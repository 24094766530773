import React from "react";
import styled from "styled-components";
import Tile from "../atoms/Tile";
import EditButton from "../molecules/EditButton";
import DeleteButton from "../molecules/DeleteButton";
import { valueExists } from "../../util/helpers";
import { useTranslation } from "react-i18next";

interface InfoTileProps {
  title?: string;
  onEdit?: () => void;
  items: { [key: string]: string | null | undefined | object };
  idPrefix: string;
  timeStamp: string;
  className?: string;
  onDelete?: () => void;
  emptyList?: boolean;
  hideItemsOnEdit?: boolean;
  allowedToDelete?: boolean;
  disableEdit?: boolean;
}

const InfoTile: React.FunctionComponent<InfoTileProps> = ({
  hideItemsOnEdit,
  allowedToDelete = true,
  ...props
}) => {
  const [t] = useTranslation();
  const Items: React.FunctionComponent<{
    items: InfoTileProps["items"];
  }> = ({ items }) => {
    const ItemElements = [];
    for (const item in items) {
      if (items.hasOwnProperty(item) && valueExists(items[item])) {
        const descriptionField = item === t("general.description");
        ItemElements.push(
          <p
            key={`${item}-${items[item]}`}
            className={`${descriptionField && "descr"}`}
          >
            <span className="label cfl">{item}</span>{" "}
            <span className="value">{items[item]}</span>
          </p>
        );
      }
    }

    return <>{ItemElements}</>;
  };

  const View = styled(Tile)`
    margin: 1.5em 1ch;
    padding: ${!props.onEdit ? "1.5em" : "0"} 1ch
      ${valueExists(props.emptyList)
        ? !props.emptyList
          ? "0"
          : "1.5em"
        : "1.5em"};

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      font-size: 1rem;
      font-weight: bold;
      word-break: break-word;
    }

    p {
      font-size: 0.75rem;
      margin: 0.75em 0;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      &.descr {
        flex-wrap: wrap;

        .label {
          flex: 1 0 100%;
          &:after {
            display: none;
          }
        }
        .value {
          flex: 1 0 100%;
          max-width: none;
        }
      }
    }

    .label {
      flex: 1;
      margin-right: 0.5ch;
      position: relative;
      font-weight: bold;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        border-bottom: 1px dotted;
        top: 1.125em;
        width: 100%;
        overflow: hidden;
        margin-left: 0.5ch;
      }
    }
    .value {
      max-width: 75%;
    }
    .delete-button {
      margin: 0 1.5em 0 auto;
    }

    .edit-button {
      ${props.disableEdit ? "visibility: hidden;" : ""}
    }
  `;

  return (
    <View
      id={`${props.idPrefix}-info-tile-${props.timeStamp}`}
      className={props.className}
    >
      <header>
        <h2 className="cfl">{props.title}</h2>
        {props.onDelete && allowedToDelete && (
          <DeleteButton
            className="delete-button"
            black
            onClick={props.onDelete}
          />
        )}
        {props.onEdit && (
          <EditButton
            idPrefix="edit-tile"
            timeStamp="10022020"
            className="edit-button"
            black
            onClick={() => {
              props.onEdit && props.onEdit();
            }}
          />
        )}
      </header>
      {valueExists(hideItemsOnEdit) ? (
        hideItemsOnEdit && <Items items={props.items} />
      ) : (
        <Items items={props.items} />
      )}
    </View>
  );
};

export default InfoTile;
