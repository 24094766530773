import styled from "styled-components";
import constants from "../_globalStyles/constants";
import Tile from "../atoms/Tile";

const Footer = styled(Tile)`
  padding: 1ch 0.75em;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  min-height: 3em;
  background: ${constants.FORM_BACKGROUND_COLOR};
`;

export default Footer;
