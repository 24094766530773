import React from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { ApolloProvider } from "react-apollo";
import { Helmet } from "react-helmet";
import client from "./util/apolloClient";
import App from "./App";
import { Router } from "react-router-dom";
import history from "./util/history";

// serviceWorker.unregister();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Helmet>
      <title>Swoosh App</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <Router history={history}>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
