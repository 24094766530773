import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import GlobalStyle from "./components/_globalStyles/globalStyles";
import Loader from "./components/atoms/Loader";
import constants from "./components/_globalStyles/constants";

import { ApolloProvider } from "react-apollo";
import customerClient from "./util/customerApolloClient";
import Equipment from "./pages/Equipment";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Customers = lazy(() => import("./pages/Customers"));
const Contracts = lazy(() => import("./pages/Contracts"));
const CreateContract = lazy(() => import("./pages/CreateContract"));
const ContractInfo = lazy(() => import("./pages/ContractInfo"));
const ContractRooms = lazy(() => import("./pages/ContractRooms"));
const ContractSite = lazy(() => import("./pages/ContractSite"));
const ContractTeam = lazy(() => import("./pages/ContractTeam"));
const CreateContractRoom = lazy(() => import("./pages/CreateContractRoom"));
const EditContractRoom = lazy(() => import("./pages/EditContractRoom"));
const ContractVendor = lazy(() => import("./pages/ContractVendor"));
const CreateVendor = lazy(() => import("./pages/CreateVendor"));
const CreateCustomer = lazy(() => import("./pages/CreateCustomer"));
const Customer = lazy(() => import("./pages/Customer"));
const Vendors = lazy(() => import("./pages/Vendors"));
const Vendor = lazy(() => import("./pages/Vendor"));
const VendorInfo = lazy(() => import("./pages/VendorInfo"));
const VendorRate = lazy(() => import("./pages/VendorRate"));
const VendorOffer = lazy(() => import("./pages/VendorOffer"));
const VendorTC = lazy(() => import("./pages/VendorTC"));
const VendorPerformance = lazy(() => import("./pages/VendorPerformance"));
const VendorDefaults = lazy(() => import("./pages/VendorDefaults"));
const VendorAddress = lazy(() => import("./pages/VendorAddress"));
const CreateEmployee = lazy(() => import("./pages/CreateEmployee"));
const Employees = lazy(() => import("./pages/Employees"));
const Employee = lazy(() => import("./pages/Employee"));
const Login = lazy(() => import("./pages/Login"));
const PasswordReset = lazy(() => import("./pages/PasswordReset"));
const PasswordChange = lazy(() => import("./pages/PasswordChange"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ContractContacts = lazy(() => import("./pages/ContractContacts"));
const ContractContactDetails = lazy(() =>
  import("./pages/ContractContactInfo")
);
const Schedules = lazy(() => import("./pages/Schedules"));
const ScheduleInfo = lazy(() => import("./pages/ScheduleInfo"));
const SchedulePerformance = lazy(() => import("./pages/SchedulePerformance"));
const CustomerInfo = lazy(() => import("./pages/CustomerInfo"));
const CustomerContacts = lazy(() => import("./pages/CustomerContacts"));
const CustomerContactInfo = lazy(() => import("./pages/CustomerContactInfo"));
const CustomerAddress = lazy(() => import("./pages/CustomerAddress"));
const ScheduleSummary = lazy(() => import("./pages/ScheduleSummary"));
const EmployeeInfo = lazy(() => import("./pages/EmployeeInfo"));
const EditVisitDay = lazy(() => import("./pages/EditVisitDay"));
const EmployeeAddress = lazy(() => import("./pages/EmployeeAddress"));
const Deviations = lazy(() => import("./pages/Deviations"));
const CreateDeviation = lazy(() => import("./pages/CreateDeviation"));
const Deviation = lazy(() => import("./pages/Deviation"));
const WorkerJob = lazy(() => import("./pages/WorkerJob"));
const WorkerJobs = lazy(() => import("./pages/WorkerJobs"));
const CompleteJob = lazy(() => import("./pages/CompleteJob"));

const Contract = lazy(() => import("./pages/Contract"));
const ContractFeedback = lazy(() => import("./pages/ContractFeedback"));
const CreateCustomTask = lazy(() => import("./pages/CreateCustomTask"));
const ContractOffer = lazy(() => import("./pages/ContractOffer"));
const HseCard = lazy(() => import("./pages/HseCard"));
const Wages = lazy(() => import("./pages/Wages"));
const WagesAdd = lazy(() => import("./pages/WagesAdd"));

const CustomerDashboard = lazy(() => import("./pages/CustomerDashboard"));
const CustomerDeviations = lazy(() => import("./pages/CustomerDeviations"));
const CustomerDeviation = lazy(() => import("./pages/CustomerDeviation"));
const CustomerCreateDeviation = lazy(() =>
  import("./pages/CustomerCreateDeviation")
);
const Feedback = lazy(() => import("./pages/Feedback"));
const PartnerFeedback = lazy(() => import("./pages/PartnerFeedback"));
const PartnerFeedbackMonth = lazy(() => import("./pages/PartnerFeedbackMonth"));
const PartnersFeedback = lazy(() => import("./pages/PartnersFeedback"));
const ContractCustomizeOffer = lazy(() =>
  import("./pages/ContractCustomizeOffer")
);
const ContractOfferEmail = lazy(() => import("./pages/ContractOfferEmail"));
const ContractTerms = lazy(() => import("./pages/ContractTerms"));
const CustomerSchedule = lazy(() => import("./pages/CustomerSchedule"));
const CustomerSchedulePreview = lazy(() =>
  import("./pages/CustomerSchedulePreview")
);
const SchedulePreview = lazy(() => import("./pages/SchedulePreview"));
const PartnerAssignments = lazy(() => import("./pages/PartnerAssignments"));
const Videos = lazy(() => import("./pages/Videos"));
const Messages = lazy(() => import("./pages/Messages"));
const MessageThread = lazy(() => import("./pages/MessageThread"));
const CreateMessage = lazy(() => import("./pages/CreateMessage"));
const CreateCustomerContact = lazy(() =>
  import("./pages/CreateCustomerContact")
);
const MyFiles = lazy(() => import("./pages/MyFiles"));

const App = () => (
  <Suspense
    fallback={
      <>
        <GlobalStyle bodyColor={constants.FORM_BACKGROUND_COLOR} />
        <Loader className="page-loader" />
      </>
    }
  >
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/contracts" component={Contracts} />
      <Route
        exact
        path="/contracts/new/:customerId?"
        component={CreateContract}
      />
      <Route
        exact
        path="/contracts/:contractId/info"
        component={ContractInfo}
      />
      <Route exact path="/contracts/:contractId" component={Contract} />
      <Route
        exact
        path="/contracts/:contractId/site"
        component={ContractSite}
      />
      <Route
        exact
        path="/contracts/:contractId/rooms"
        component={ContractRooms}
      />
      <Route
        exact
        path="/contracts/:contractId/contacts"
        component={ContractContacts}
      />
      <Route
        exact
        path="/contracts/:contractId/offer"
        component={ContractCustomizeOffer}
      />
      <Route
        exact
        path="/contracts/:contractId/offer/terms"
        component={ContractTerms}
      />
      <Route
        exact
        path="/contracts/:contractId/offer/email"
        component={ContractOfferEmail}
      />
      <Route
        exact
        path="/contracts/:contractId/contacts/:kind"
        component={ContractContactDetails}
      />
      <Route
        exact
        path="/contracts/:contractId/rooms/new"
        component={CreateContractRoom}
      />
      <Route
        exact
        path="/contracts/:contractId/rooms/:roomId"
        component={EditContractRoom}
      />
      <Route
        exact
        path="/contracts/:contractId/vendor"
        component={ContractVendor}
      />
      <Route
        exact
        path="/contracts/:contractId/team"
        component={ContractTeam}
      />
      <Route
        exact
        path="/contracts/:contractId/schedules/:scheduleId"
        component={ScheduleInfo}
      />
      <Route
        exact
        path="/contracts/:contractId/schedules/:scheduleId/performance"
        component={SchedulePerformance}
      />
      <Route
        exact
        path="/contracts/:contractId/schedules/:scheduleId/preview"
        component={SchedulePreview}
      />
      <Route
        exact
        path="/contracts/:contractId/schedules/"
        component={Schedules}
      />
      <Route
        exact
        path="/contracts/:contractId/equipment/"
        component={Equipment}
      />
      <Route
        exact
        path="/contracts/:contractId/feedback/"
        component={ContractFeedback}
      />
      <Route
        exact
        path="/contracts/:contractId/schedules/:scheduleId/summary"
        component={ScheduleSummary}
      />
      <Route exact path="/customers" component={Customers} />
      <Route exact path="/customers/new" component={CreateCustomer} />
      <Route exact path="/customers/:customerId" component={Customer} />
      <Route
        exact
        path="/customers/:customerId/info"
        component={CustomerInfo}
      />
      <Route
        exact
        path="/customers/:customerId/contacts"
        component={CustomerContacts}
      />
      <Route
        exact
        path="/customers/:customerId/contacts/new"
        component={CreateCustomerContact}
      />
      <Route
        exact
        path="/customers/:customerId/contacts/:contactId"
        component={CustomerContactInfo}
      />
      <Route
        exact
        path="/customers/:customerId/address"
        component={CustomerAddress}
      />
      <Route
        exact
        path="/customers/:customerId/contracts"
        component={Contracts}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/new"
        component={CreateContract}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId"
        component={Contract}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/feedback"
        component={ContractFeedback}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/equipment"
        component={Equipment}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/offer"
        component={ContractCustomizeOffer}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/offer/terms"
        component={ContractTerms}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/offer/email"
        component={ContractOfferEmail}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/info"
        component={ContractInfo}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/site"
        component={ContractSite}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/rooms"
        component={ContractRooms}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/contacts"
        component={ContractContacts}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/contacts/:kind"
        component={ContractContactDetails}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/rooms/new"
        component={CreateContractRoom}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/rooms/:roomId"
        component={EditContractRoom}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/vendor"
        component={ContractVendor}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/team"
        component={ContractTeam}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/schedules/:scheduleId"
        component={ScheduleInfo}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/schedules/:scheduleId/performance"
        component={SchedulePerformance}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/schedules/:scheduleId/preview"
        component={SchedulePreview}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/schedules/"
        component={Schedules}
      />
      <Route
        exact
        path="/customers/:customerId/contracts/:contractId/schedules/:scheduleId/summary"
        component={ScheduleSummary}
      />
      <Route exact path="/employees" component={Employees} />
      <Route exact path="/employees/new" component={CreateEmployee} />
      <Route exact path="/employees/:employeeId" component={Employee} />
      <Route
        exact
        path="/employees/:employeeId/info"
        component={EmployeeInfo}
      />
      <Route exact path="/vendors" component={Vendors} />
      <Route exact path="/vendors/new" component={CreateVendor} />
      <Route exact path="/vendors/:id" component={Vendor} />
      <Route exact path="/vendors/:id/info" component={VendorInfo} />
      <Route exact path="/vendors/:id/address" component={VendorAddress} />
      <Route exact path="/vendors/:id/defaults" component={VendorDefaults} />
      <Route exact path="/vendors/:id/defaults/rate" component={VendorRate} />
      <Route exact path="/vendors/:id/defaults/offer" component={VendorOffer} />
      <Route
        exact
        path="/vendors/:id/defaults/terms-and-conditions"
        component={VendorTC}
      />

      <Route
        exact
        path="/vendors/:id/defaults/performance"
        component={VendorPerformance}
      />

      <Route
        exact
        path="/vendors/:id/employees/new"
        component={CreateEmployee}
      />
      <Route exact path="/vendors/:id/employees" component={Employees} />
      <Route exact path="/visitday/:id/:contractId?" component={EditVisitDay} />
      <Route
        exact
        path="/vendors/:vendorId/employees/:employeeId"
        component={Employee}
      />
      <Route
        exact
        path="/vendors/:vendorId/partner-assignments/:year/:weekNumber/:partnerId?"
        component={PartnerAssignments}
      />
      <Route
        exact
        path="/vendors/:vendorId/employees/:employeeId/info"
        component={EmployeeInfo}
      />
      <Route
        exact
        path="/vendors/:vendorId/employees/:employeeId/address"
        component={EmployeeAddress}
      />
      <Route
        exact
        path="/vendors/:vendorId/employees/:employeeId/hsecard"
        component={HseCard}
      />
      <Route
        exact
        path="/vendors/:vendorId/employees/:employeeId/wages"
        component={Wages}
      />
      <Route
        exact
        path="/employees/:employeeId/address"
        component={EmployeeAddress}
      />
      <Route exact path="/employees/:employeeId/hsecard" component={HseCard} />
      <Route exact path="/employees/:employeeId/wages" component={Wages} />
      <Route
        exact
        path="/employees/:employeeId/wages/new"
        component={WagesAdd}
      />
      <Route exact path="/profile" component={Employee} />
      <Route exact path="/profile/info" component={EmployeeInfo} />
      <Route exact path="/profile/address" component={EmployeeAddress} />
      <Route exact path="/profile/wages" component={Wages} />
      <Route exact path="/profile/wages/new" component={WagesAdd} />
      <Route exact path="/profile/hsecard" component={HseCard} />
      <Route exact path="/business" component={Vendor} />
      <Route exact path="/business/info" component={VendorInfo} />
      <Route exact path="/business/defaults" component={VendorDefaults} />
      <Route exact path="/business/defaults/rate" component={VendorRate} />
      <Route exact path="/business/defaults/offer" component={VendorOffer} />
      <Route
        exact
        path="/business/defaults/terms-and-conditions"
        component={VendorTC}
      />
      <Route
        exact
        path="/business/defaults/performance"
        component={VendorPerformance}
      />
      <Route exact path="/business/address" component={VendorAddress} />
      <Route exact path="/deviations" component={Deviations} />
      <Route exact path="/deviations/new" component={CreateDeviation} />
      <Route exact path="/deviations/:id" component={Deviation} />
      <Route
        exact
        path="/jobs/:year/:weekNumber/:date?"
        component={WorkerJobs}
      />
      <Route
        exact
        path="/jobs/:year/:weekNumber/:date/:id"
        component={WorkerJob}
      />
      <Route
        exact
        path="/jobs/:year/:weekNumber/:date/:id/complete"
        component={CompleteJob}
      />
      <Route
        exact
        path="/jobs/:year/:weekNumber/:date/:id/new"
        component={CreateCustomTask}
      />
      <Route
        exact
        path="/business/employees/:id/address"
        component={EmployeeAddress}
      />
      <Route exact path="/nps" component={PartnerFeedback} />
      <Route exact path="/nps/:date" component={PartnerFeedbackMonth} />
      <Route exact path="/nps-partners" component={PartnersFeedback} />
      <Route
        exact
        path="/nps-partners/:partnerId"
        component={PartnerFeedback}
      />
      <Route
        exact
        path="/nps-partners/:partnerId/:date"
        component={PartnerFeedbackMonth}
      />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup/:hex" component={SignUp} />
      <Route exact path="/reset_password" component={PasswordReset} />
      <Route exact path="/reset_password/:hex" component={PasswordChange} />
      <Route exact path="/contract_offers/:hex" component={ContractOffer} />
      <Route
        exact
        path="/partner-assignments/:year/:weekNumber/:partnerId?"
        component={PartnerAssignments}
      />
      <Route exact path="/educational-videos" component={Videos} />
      <Route exact path="/files" component={MyFiles} />
      <Route exact path="/messages" component={Messages} />
      <Route exact path="/messages/new" component={CreateMessage} />
      <Route exact path="/message/:threadId" component={MessageThread} />

      <ApolloProvider client={customerClient}>
        <Route
          exact
          path="/customer_dashboard/:hex/create_deviation"
          component={CustomerCreateDeviation}
        />
        <Route
          exact
          path="/customer_dashboard/:hex"
          component={CustomerDashboard}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/deviations"
          component={CustomerDeviations}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/deviations/:id"
          component={CustomerDeviation}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/sanitation_plan"
          component={CustomerSchedule}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/sanitation_plan/:contractId"
          component={CustomerSchedulePreview}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/messages"
          component={Messages}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/message/:threadId"
          component={MessageThread}
        />
        <Route
          exact
          path="/customer_dashboard/:hex/messages/new"
          component={CreateMessage}
        />
        <Route exact path="/feedback/:hex/:score?" component={Feedback} />
      </ApolloProvider>
    </Switch>
  </Suspense>
);

export default App;
