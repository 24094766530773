import React, { useState } from "react";
import styled from "styled-components";
import { useTimeout } from "../../util/hooks";
import constants from "../_globalStyles/constants";

const Spinner = styled.div`
  border: ${(props: LoaderProps): string | undefined => props.width} solid
    ${(props: LoaderProps): string | undefined =>
      props.color || constants.SWOOSH_BRAND_COLOR};
  border-top: ${(props: LoaderProps): string | undefined => props.width} solid
    transparent;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  className?: string;
  timeout?: number;
  width?: string;
  color?: string;
}

const Loader: React.FunctionComponent<LoaderProps> = ({
  className,
  timeout = 1000,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  useTimeout(() => setLoading(true), timeout);

  return loading ? (
    <div className={className}>
      <Spinner {...props} />
    </div>
  ) : null;
};

export default Loader;
