import * as React from "react";
import { useState, useMemo } from "react";
import styled from "styled-components";
import { Input, LiveArea } from "react-inclusive-components";
import constants from "../_globalStyles/constants";
import {
  pxToEm,
  valueExists,
  capitalizeString,
  noop
} from "../../util/helpers";
import { InputProps, InputState } from "../../interfaces/Input";

/** Text input component that is used in all our forms.  */

const setBackgroundColor = (props: InputProps): string => {
  if (props.filled) return constants.INPUT_FILLED_BACKGROUND;
  return constants.INPUT_REGULAR_BACKGROUND;
};

const setLabelPosition = (props: InputProps): string => {
  if (props.activated === "true") return "calc(100% - 2.25em)";
  return "0.375em";
};

const setLabelSize = (props: InputProps): string => {
  if (props.activated === "true") return `0.5em`;
  return "1em";
};

const setLabelColor = (props: InputProps): string => {
  if (props.valid === "true") return constants.INPUT_SUCCESS;
  if (props.invalid === "true") return constants.INPUT_DANGER;
  if (props.activated === "true") return constants.INPUT_ACTIVE;
  return constants.INPUT_EMPTY_LABEL;
};

const setInputColor = (props: InputProps): string => {
  if (props.valid === "true") return constants.INPUT_SUCCESS;
  if (props.invalid === "true") return constants.INPUT_DANGER;
  return constants.INPUT_ACTIVE;
};

const StyledInput = styled(({ field, value = "", ...props }) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    field && field.onChange(e);
    props.onChange(e);
  };

  return (
    <Input
      {...props}
      {...field}
      value={value}
      onChange={handleChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );
})`
  -webkit-appearance: none;
  width: 100%;
  padding-left: 0;
  padding-top: 1.5em;
  padding-bottom: 0.375em;
  background-color: ${setBackgroundColor};
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${setInputColor};
  font-size: 1em;
  color: ${constants.INPUT_VALUE_COLOR};
  caret-color: ${setInputColor};

  & + label {
    position: absolute;
    left: 0;
    bottom: ${setLabelPosition};
    font-size: ${setLabelSize};
    color: ${setLabelColor};
    transition: 0.2s ease all;
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  :-webkit-autofill {
    font-size: 1em !important;
    color: ${constants.INPUT_VALUE_COLOR} !important;

    & + label {
      bottom: 3.75em;
      font-size: 0.5em;
      color: ${constants.INPUT_ACTIVE};
    }
  }

  &:required + label:after {
    display: inline-block;
    content: "*";
  }

  &[disabled] {
    color: ${constants.BUTTON_INACTIVE};
    -webkit-text-fill-color: ${constants.BUTTON_INACTIVE};
    border-color: ${constants.BUTTON_INACTIVE};
    pointer-events: none;
    & + label {
      color: ${constants.BUTTON_INACTIVE};
    }
    & ~ button {
      pointer-events: none;
    }
  }
`;

const StyledInputWrap = styled.div`
  position: relative;

  &.error {
    display: block !important;
  }

  & ~ .errorMessage {
    display: flex;
    margin-bottom: 1.5em;
    margin-top: -1.5em;
    font-size: ${pxToEm(12, 16)}em;
    color: ${constants.INPUT_DANGER};
  }

  & ~ .successMessage {
    display: flex;
    margin-bottom: 1.5em;
    margin-top: -1.5em;
    font-size: ${pxToEm(12, 16)}em;
    color: ${constants.INPUT_SUCCESS};
  }
  & ~ .inputHint {
    display: flex;
    margin-bottom: 1.5em;
    margin-top: -1.5em;
    font-size: ${pxToEm(12, 16)}em;
    color: ${constants.SWOOSH_BRAND_COLOR};
    text-align: left;
  }
`;

const TextInput: React.FunctionComponent<InputProps> = ({
  className,
  children,
  validationMethod = noop,
  timeStamp,
  idPrefix,
  id,
  form,
  value,
  name,
  inputFieldHint,
  ...props
}: InputProps) => {
  const fieldName = props.field ? props.field?.name : name;

  const [state, setState] = useState<InputState>({
    errorMessage: undefined,
    inputValue: value ? value : props.field ? props.field.value : "",
    successMessage: "",
    activated: valueExists(props.field?.value) || valueExists(value)
  });

  useMemo(() => {
    if (!valueExists(value)) {
      setState({
        ...state,
        errorMessage:
          fieldName && form?.errors[fieldName]
            ? form?.errors[fieldName]
            : undefined,
        activated: valueExists(props.field?.value),
        inputValue: props.field?.value
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field?.value, fieldName && form?.errors[fieldName]]);

  useMemo(() => {
    if (!valueExists(props.field?.value)) {
      setState({
        ...state,
        activated: valueExists(value),
        inputValue: value || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      activated: true
    });
    props.onFocus && props.onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      activated: valueExists(state.inputValue)
    });
    props.onBlur && props.onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      successMessage: validationMethod(e.target.value),
      errorMessage: form?.errors[`${fieldName}`],
      inputValue: e.target.value
    });
    props.onChange && props.onChange(e);
  };

  return (
    <>
      <StyledInputWrap
        className={
          className ? `text-input-wrapper ${className}` : `text-input-wrapper`
        }
      >
        <StyledInput
          {...props}
          id={`${idPrefix}-${id}-${timeStamp}`}
          activated={state.activated.toString()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          invalid={(
            valueExists(state.errorMessage) || valueExists(props.invalid)
          ).toString()}
          valid={(
            valueExists(state.successMessage) && !props.invalid
          ).toString()}
          onChange={handleChange}
          labelPosition="after"
          value={state.inputValue}
        />
        {children}
      </StyledInputWrap>
      {valueExists(state.errorMessage) && !valueExists(state.successMessage) && (
        <LiveArea
          id={`${idPrefix}-${id}-error-message-${timeStamp}`}
          className="errorMessage"
        >
          {state.errorMessage}
        </LiveArea>
      )}
      {valueExists(state.successMessage) && (
        <LiveArea
          id={`${idPrefix}-${id}-success-message-${timeStamp}`}
          className="successMessage"
        >
          {capitalizeString(state.successMessage, true)}
        </LiveArea>
      )}
      {inputFieldHint && !state.successMessage && !state.errorMessage && (
        <LiveArea className="inputHint">
          {capitalizeString(inputFieldHint, true)}
        </LiveArea>
      )}
    </>
  );
};

export default TextInput;
