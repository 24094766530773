import Radiobutton from "../atoms/Radiobutton";
import React from "react";
import { FieldInputProps, FormikHelpers, FormikValues } from "formik";

interface ConnecterRadioButtonProps {
  form: FormikHelpers<FormikValues>;
  field: FieldInputProps<string | number | undefined>;
  value?: string;
  checkedFilled?: boolean;
  inverseRadio?: boolean;
}

const ConnectedRadioButton: React.FunctionComponent<ConnecterRadioButtonProps> = ({
  field,
  form,
  inverseRadio,
  ...props
}) => {
  const handleChange = (e?: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue(field.name, e?.target.value);
  };

  return (
    <Radiobutton
      {...props}
      checked={field.value ? field.value === props.value : false}
      onChange={handleChange}
      inverseRadio={inverseRadio}
    />
  );
};

export default ConnectedRadioButton;
