import React from "react";
import { ReactComponent as Splash } from "../../assets/icons/swooshSplash.svg";
import constants from "../_globalStyles/constants";
import styled from "styled-components";
import { pxToEm, valueExists } from "../../util/helpers";

interface SwooshSplashProps {
  positionTop?: number;
  positionLeft?: number;
  fill?: string;
  className?: string;
}

const SwooshSplash: React.FunctionComponent<SwooshSplashProps> = ({
  positionTop,
  positionLeft,
  fill = constants.SPLASH_COLOR,
  ...props
}) => {
  const getFill = (): string => fill;
  const getPositionTop = (): string | undefined =>
    valueExists(positionTop) ? `${pxToEm(positionTop, 16)}rem` : undefined;
  const getPositionLeft = (): string | undefined =>
    valueExists(positionLeft) ? `${pxToEm(positionLeft, 16)}rem` : undefined;

  const SwooshSplashView = styled(Splash)`
    position: relative;
    top: ${getPositionTop};
    left: ${getPositionLeft};
    width: 100%;
    height: ${pxToEm(260, 16)}rem;
    g {
      fill: ${getFill};
    }
  `;

  return <SwooshSplashView {...props} />;
};

export default SwooshSplash;
