import React, { useState } from "react";
import styled from "styled-components";
import { getCookieByName } from "../../util/cookieProvider";
import ToggleGroup from "../organisms/ToggleGroup";
import { getObjectValue, setLocale } from "../../util/helpers";
import constants from "../_globalStyles/constants";
import { useQuery, useMutation } from "react-apollo";
import updateMyLocale from "../../graphql/mutations/updateMyLocale";
import { userLocale } from "../../graphql/queries/user";
import { useTranslation } from "react-i18next";

const locales = ["en", "no"];

const LocaleSwitcher: React.FunctionComponent = props => {
  const initialLocale = getCookieByName("locale");
  const [locale, switchLocale] = useState(initialLocale || "no");
  const { i18n } = useTranslation();

  const { data, loading, error } = useQuery(userLocale);

  const [mutate] = useMutation(updateMyLocale);

  if (!loading && !error && data) {
    const userLocale = getObjectValue(data, "me.person.locale");

    if (userLocale && userLocale.toLowerCase() !== locale) {
      switchLocale(userLocale.toLowerCase());
      setLocale(userLocale.toLowerCase());
      i18n.changeLanguage(userLocale.toLowerCase());
    }
  }

  const handleClick = (localeItemIndex: string, localeItem: any) => {
    if (initialLocale === localeItem.item) {
      return;
    }

    if (!getObjectValue(data, "me.person.locale")) {
      setLocale(localeItem.item);
      switchLocale(localeItem.item);
      i18n.changeLanguage(localeItem.item);
    } else {
      mutate({ variables: { locale: localeItem.item.toUpperCase() } }).then(
        () => {
          setLocale(localeItem.item);
          switchLocale(localeItem.item);
          i18n.changeLanguage(localeItem.item);
        }
      );
    }
  };

  const View = styled(ToggleGroup as any)`
    border: 1px solid ${constants.MAIN_LIGHT_COLOR};

    .textLabel {
      color: ${constants.MAIN_LIGHT_COLOR};
    }

    [aria-pressed="true"] {
      background-color: ${constants.MAIN_LIGHT_COLOR};
      .textLabel {
        color: ${constants.SWOOSH_BRAND_COLOR};
      }
    }
  `;

  return (
    <View
      {...props}
      singleLayer
      className="locale-switcher"
      items={locales.map(item => ({
        item,
        textLabel: item
      }))}
      initialValues={[locale]}
      onClickAction={handleClick}
    />
  );
};

export default LocaleSwitcher;
