import * as React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import constants from "../_globalStyles/constants";
import { pxToEm } from "../../util/ts/helpers";
import history from "../../util/history";

const AddButtonView = styled(Button as any)`
  min-width: ${pxToEm(32, 16)}rem;
  display: flex;
  align-items: center;
  svg {
    background: ${constants.SWOOSH_BRAND_COLOR};
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    g {
      fill: transparent;
    }
  }

  .text-label {
    color: ${constants.LINK_COLOR};
    font-weight: 700;
    margin-left: 1ch;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:disabled {
    cursor: pointer;
    background-color: transparent;
    .text-label {
      color: ${constants.BUTTON_INACTIVE};
    }
    svg {
      background: ${constants.BUTTON_INACTIVE};
    }
  }
`;

interface AddButtonProps {
  className?: string;
  redirectTo?: string;
  textLabel?: string;
  hiddenText?: string;
  noRedirect?: boolean;
  onClick?: (e: MouseEvent) => unknown;
  id?: string;
  disabled?: boolean;
}

const AddButton: React.FC<AddButtonProps> = ({
  redirectTo,
  textLabel,
  hiddenText,
  noRedirect,
  onClick,
  ...props
}) => {
  const handleClick = (e: MouseEvent): void => {
    if (!noRedirect && redirectTo) history.push(redirectTo);
    onClick && onClick(e);
  };

  return (
    <AddButtonView onClick={handleClick} transparent {...props}>
      <Icon aria-hidden="true" name="plus_small" />
      {textLabel && <span className="text-label">{textLabel}</span>}
      {hiddenText && <span className="ric-hidden">{hiddenText}</span>}
    </AddButtonView>
  );
};

export default AddButton;
