import React, { useState } from "react";
import Loader from "../components/atoms/Loader";
import styled from "styled-components";
import Header from "../components/organisms/Header";
import GlobalStyle from "../components/_globalStyles/globalStyles";
import { Redirect, RouteComponentProps } from "react-router";
import { ContractRouterProps } from "../interfaces/routerProps";
import { useTranslation } from "react-i18next";
import {
  backRouteConstructor,
  isAccessDenied,
  getObjectValue
} from "../util/helpers";
import { Helmet } from "react-helmet";
import EquipmentTile from "../components/organisms/EquipmentTile";
import AddButton from "../components/molecules/AddButton";
import constants from "../components/_globalStyles/constants";
import Form from "../components/_functional/Form";
import RadiosList from "../components/organisms/RadiosList";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ContractEquipmentData,
  ContractEquipmentDataVariables
} from "../interfaces/graphql/ContractEquipmentData";
import contractEquipment from "../graphql/queries/contractEquipment";
import addEquipmentMutation from "../graphql/mutations/addEquipment";
import {
  AddEquipmentData,
  AddEquipmentDataVariables
} from "../interfaces/graphql/AddEquipmentData";
import ProfileHeader from "../components/organisms/ProfileHeader";

type Props = RouteComponentProps<ContractRouterProps>;

const Equipment = (props: Props): React.ReactElement | null => {
  const [t] = useTranslation();
  const backRoute = backRouteConstructor(props.match.url);
  const contractId = props.match.params.contractId;
  const [selectOther, toggleSelectOther] = useState(false);

  const EquipmentView = styled.div`
    .equipment-list {
      margin: 0 1ch;
    }

    .radios-list {
      margin-bottom: 6em;
    }

    .buttonsContainer {
      margin: 0 auto;

      button + button {
        margin-left: 1ch;
      }
    }

    .equipment-contact-tile {
      margin: 1ch 0;
    }

    .add-equipment {
      margin: 1.5em 1ch;
    }
  `;

  const { data, loading, error, refetch } = useQuery<
    ContractEquipmentData,
    ContractEquipmentDataVariables
  >(contractEquipment, {
    variables: { contractId }
  });

  const [addEquipment] = useMutation<
    AddEquipmentData,
    AddEquipmentDataVariables
  >(addEquipmentMutation);

  if (loading || !data)
    return (
      <>
        <GlobalStyle />
        <Header
          timeStamp="18032019"
          idPrefix="t-employee"
          title={t("general.equipment")}
          backRoute={backRoute}
        />
        <Loader className="page-loader" />
      </>
    );

  if (isAccessDenied(error)) {
    return <Redirect to="/" />;
  }

  const additionalEquipment = data.equipment.filter(equipment => {
    let presentInContract = false;

    data.contract.contractEquipment?.forEach(item => {
      if (item.equipment.id === equipment.id) {
        item.equipment.name === "OTHER"
          ? (presentInContract = false)
          : (presentInContract = true);
      }
    });

    return !presentInContract;
  });

  const addEquipmentFormFields = additionalEquipment.map(item => {
    return {
      fieldName: "selectedEquipment",
      value: item.name,
      id: item.id,
      label: t(`equipment.names.${item.name}`)
    };
  });

  return (
    <>
      <Helmet>
        <title>Swoosh App - {t("general.equipment")} - </title>
      </Helmet>
      <GlobalStyle />

      <EquipmentView>
        {!selectOther && (
          <>
            <Header
              timeStamp="18032019"
              idPrefix="t-employee"
              title={t("general.equipment")}
              backRoute={backRoute}
            />
            <ProfileHeader
              name={`${getObjectValue(data, "contract.name")} (${getObjectValue(
                data,
                "contract.uid"
              )})`}
              hideHeaderElements
              subTitle={getObjectValue(data, "contract.customer.name")}
            />
            {data.contract.contractEquipment?.map(item => (
              <EquipmentTile
                key={`${item.equipment.id}-${item.id}`}
                name={t(`equipment.names.${item.equipment.name}`)}
                initialFrequency={item.equipmentFrequency.name}
                initialAmount={`${item.quantity}`}
                frequencies={data.equipmentFrequencies}
                timeStamp="08012020"
                idPrefix="t-equipment"
                equipmentId={item.id}
                frequencyId={item.equipmentFrequency.id}
                amount={item.quantity}
                description={item.description}
                onEquipmentDelete={() => refetch()}
              />
            ))}
            <AddButton
              className="add-equipment"
              textLabel={t("general.other")}
              onClick={(): void => toggleSelectOther(true)}
            />
          </>
        )}
        {selectOther && (
          <>
            <Header
              timeStamp="18032019"
              idPrefix="t-employee"
              title={t("general.equipment")}
              backCallback={() => toggleSelectOther(false)}
            />
            <GlobalStyle bodyColor={constants.FORM_BACKGROUND_COLOR} />
            <ProfileHeader
              name={`${getObjectValue(data, "contract.name")} (${getObjectValue(
                data,
                "contract.uid"
              )})`}
              hideHeaderElements
              subTitle={getObjectValue(data, "contract.customer.name")}
            />
            <Form
              className="radios-list"
              idPrefix={"t-equipment"}
              timeStamp={"08012020"}
              initialValues={{ selectedEquipment: undefined }}
              onSubmit={(values): void => {
                addEquipment({
                  variables: {
                    contractId,
                    Input: {
                      equipmentId: values.selectedEquipment,
                      description: ""
                    }
                  }
                }).then(() => refetch());
                toggleSelectOther(false);
              }}
              onCancel={(): void => toggleSelectOther(false)}
              withFooter
            >
              <RadiosList
                items={addEquipmentFormFields}
                idPrefix={"t-equipment"}
                timeStamp={"08012020"}
                header={t("equipment.select")}
                className={"equipment-list"}
              />
            </Form>
          </>
        )}
      </EquipmentView>
    </>
  );
};

export default Equipment;
