import React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import constants from "../_globalStyles/constants";
import { PageComponentProps } from "../../interfaces/common";

const ToggleButtonView = styled(Button as any)`
  border: 1px solid ${constants.LINK_COLOR};
  border-radius: 0;
  .textLabel {
    color: ${constants.LINK_COLOR};
  }

  &:disabled {
    cursor: pointer;
    background-color: ${constants.INPUT_REGULAR_BACKGROUND};
    .textLabel {
      color: ${constants.BUTTON_INACTIVE};
    }
  }

  &.selected {
    background: ${constants.BUTTON_ACTIVE_HOVER};
    .textLabel {
      color: ${constants.BUTTON_TEXT_PRIMARY};
    }
  }

  &[aria-pressed="true"] {
    background: ${constants.LINK_COLOR};
    z-index: 1;
    .textLabel {
      color: ${constants.BUTTON_TEXT_PRIMARY};
    }
  }
`;

interface ToggleButtonProps extends PageComponentProps {
  textLabel?: string;
  hiddenText?: string;
  initialSelected: any;
  onClick: () => void;
  pressed: boolean;
}

const ToggleButton: React.FunctionComponent<ToggleButtonProps> = ({
  textLabel,
  hiddenText,
  initialSelected,
  onClick,
  pressed,
  idPrefix,
  ...props
}) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <ToggleButtonView
      onClick={handleClick}
      transparent
      role="button"
      aria-pressed={pressed}
      id={idPrefix}
      {...props}
      className={`toggleButton ${initialSelected ? "selected" : ""} ${
        props.className
      }`}
    >
      {textLabel && <span className="textLabel">{textLabel}</span>}
      <span className="ric-hidden">{hiddenText}</span>
    </ToggleButtonView>
  );
};

export default ToggleButton;
