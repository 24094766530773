import React from "react";
import styled from "styled-components";
import { pxToEm } from "../../util/helpers";
import constants from "../_globalStyles/constants";

const FieldSetView = styled.fieldset`
  border: none;
  margin: 1.5em 0;

  > div {
    margin-bottom: 0;
  }
  > div + div {
    margin: 1.5em 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  legend {
    width: 100%;
    text-align: left;
    color: ${constants.TEXT_COLOR_DARK};
    font-size: ${pxToEm(14, 16)}rem;
    font-weight: bold;
  }
`;

interface FieldSetProps {
  labelId?: string;
}

const FieldSet: React.FunctionComponent<FieldSetProps> = ({
  labelId,
  ...props
}) => {
  return <FieldSetView aria-labelledby={labelId} {...props} />;
};

export default FieldSet;
