import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/icons/swooshLogo.svg";
import constants from "../_globalStyles/constants";

interface SwooshLogoProps {
  fill?: string;
  className?: string;
}

const SwooshLogo: React.FunctionComponent<SwooshLogoProps> = ({
  fill = constants.LOGO_COLOR,
  ...props
}) => {
  const SwooshLogoView = styled(Logo)`
    display: block;
    width: 20ch;
    g {
      fill: ${fill};
    }
  `;

  return <SwooshLogoView fill={fill} {...props} />;
};

export default SwooshLogo;
