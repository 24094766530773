import gql from "graphql-tag";

export default gql`
  mutation AddEquipmentData(
    $contractId: ID!
    $Input: CreateContractEquipmentInput!
  ) {
    createContractEquipment(contractId: $contractId, input: $Input) {
      id
      quantity
      description
      contract {
        id
      }
      equipment {
        id
      }
      equipmentFrequency {
        id
      }
    }
  }
`;
