import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getMobileOperatingSystem } from "../../util/helpers";

const NavigationItemView = styled.div`
  display: flex;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
    letter-spacing: 0.5px;
    font-weight: 400;
    width: 100%;
    font-size: 1rem;
    padding: 0.375rem 0;
  }
`;

interface NavigationItemProps {
  name: string;
  onClose: () => void;
  id: string;
  iosApp: string;
  iosLink: string;
  androidApp: string;
  androidLink: string;
  webLink: string;
}

const NavigationItem: React.FunctionComponent<NavigationItemProps> = props => {
  const nameConstructor = (str: string) => {
    const byArr = str.split("_");
    if (byArr.length === 1) {
      return str;
    }
    return byArr.splice(1)[0];
  };

  const [t] = useTranslation();

  const os = getMobileOperatingSystem();

  const handleClick = (e: any) => {
    e.preventDefault();
    if (os === "ios") {
      setTimeout(function() {
        window.open(props.iosApp, "_blank");
      }, 300);
      window.open(props.iosLink, "_blank");
    } else if (os === "android") {
      setTimeout(function() {
        window.open(props.androidLink, "_blank");
      }, 300);
      window.open(props.androidApp, "_blank");
    } else {
      window.open(props.webLink, "_blank");
    }

    props.onClose();
  };

  return (
    <NavigationItemView>
      <a
        className="cfl"
        aria-label={props.name}
        id={props.id}
        onClick={handleClick}
        href="# "
      >
        <span>{t(`permissions.${nameConstructor(props.name)}`)}</span>
      </a>
    </NavigationItemView>
  );
};

export default NavigationItem;
