import React from "react";
import styled from "styled-components";
import ProfilePicture from "../atoms/ProfilePicture";
import { getObjectValue, pxToEm } from "../../util/helpers";
import Tile from "../atoms/Tile";
import constants from "../_globalStyles/constants";
import ContractStatus from "../molecules/ContractStatus";
import PhoneAndMessageButtons from "../molecules/PhoneAndMessageButtons";
import FeedbackScore from "../molecules/FeedbackScore";
import { valueExists } from "../../util/helpers";

const ProfileHeaderView = styled.div`
  .profilePicture {
    margin-left: 0.32ch;
  }
  .profileInfo {
    min-height: 6em;
    box-shadow: -1px 3px 3px 0 ${constants.BOX_SHADOW_LIGHT_COLOR};
    word-break: break-word;
    display: flex;
    align-items: center;
    .title-block {
      margin-left: 2ch;
      @media (max-width: ${pxToEm(375, 16)}rem) {
        margin-left: 1ch;
      }
    }

    .title-block-custom {
      margin: ${pxToEm(20, 16)}rem 2ch;
    }

    .sub-title {
      font-size: ${pxToEm(14, 16)}rem;
    }

    .score {
      margin: 0 ${pxToEm(20, 16)}rem 0 auto;
    }

    h2 {
      font-size: ${pxToEm(18, 16)}rem;
      font-weight: bold;
      letter-spacing: 0;
    }

    .rating {
      display: flex;
      align-items: center;
      height: 1.5em;
    }

    .ratingCount {
      font-size: 0.5rem;
      margin-left: 1ch;
    }
    .contact-buttons {
      display: flex;
      margin-left: auto;
      margin-right: 2ch;
      a {
        margin-left: 2ch;
      }
    }
  }
`;

interface ProfileHeaderProps {
  hideHeaderElements?: boolean;
  profileImage?: string;
  ratingCount?: string;
  rating?: number;
  name?: string;
  subTitle?: string;
  contractInfo?: any;
  contacts?: any[];
  className?: string;
  feedbackRating?: number;
}

const ProfileHeader: React.FunctionComponent<ProfileHeaderProps> = props => {
  return (
    <ProfileHeaderView className={props.className}>
      <Tile className="profileInfo">
        {!props.hideHeaderElements && (
          <ProfilePicture
            className="profilePicture"
            placeholder={getObjectValue(props, "name[0]")}
            image={props.profileImage}
          />
        )}
        <div
          className={
            props.hideHeaderElements ? "title-block-custom" : "title-block"
          }
        >
          <h2 className="cfl">{props.name}</h2>
          {props.subTitle && <h6 className="sub-title">{props.subTitle}</h6>}
          {props.contractInfo && (
            <ContractStatus status={props.contractInfo.status} />
          )}
        </div>
        {valueExists(props.feedbackRating) && (
          <FeedbackScore className="score" score={props.feedbackRating} />
        )}
        {props.contacts && (
          <div className="contact-buttons">
            <PhoneAndMessageButtons
              buttons={props.contacts}
              idPrefix="t-employee"
              timeStamp="14052019"
            />
          </div>
        )}
      </Tile>
    </ProfileHeaderView>
  );
};

export default ProfileHeader;
