import React from "react";
import constants from "../_globalStyles/constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ContractStatusView = styled.div`
  font-size: 0.875rem;
  .status {
    margin-right: 1ch;
    font-style: normal;
  }
  .status-TERMINATED,
  .status-CANCELLED,
  .status-REJECTED {
    color: ${constants.INPUT_DANGER};
  }

  .status-PENDING,
  .status-ACCEPTED {
    color: ${constants.SWOOSH_BRAND_COLOR};
  }

  .status-ACTIVE {
    color: ${constants.STATUS_COLOR_SOLVED};
  }

  .status-CREATED {
    color: ${constants.STATUS_COLOR_NOT_SEND};
  }
`;

interface EmptyListProps {
  status: string;
}

const ContractStatus: React.FunctionComponent<EmptyListProps> = ({
  status
}) => {
  const [t] = useTranslation();

  return (
    <ContractStatusView className="status-block cfl">
      {status && (
        <em className={`status-${status} status cfl`}>
          {t(`contract.statuses.${status}`)}
        </em>
      )}
    </ContractStatusView>
  );
};

export default ContractStatus;
