import React, { useState, useEffect, ReactElement } from "react";
import styled from "styled-components";
import Downshift from "downshift";
import TextInput from "../atoms/TextInput";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";
import {
  findFirstInList,
  getObjectValue,
  propEquals,
  pxToEm,
  valueExists,
  capitalizeString
} from "../../util/helpers";
import constants from "../_globalStyles/constants";
import { FieldInputProps, FormikProps, FormikValues } from "formik";

const SelectView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  .clear-button {
    background: transparent;
    align-self: flex-end;
    color: ${constants.SWOOSH_BRAND_COLOR};
    margin: 0.375em 0;
    -webkit-appearance: none;
    border: none;
  }
  .expandButton {
    position: absolute;
    right: 0;
    bottom: 0.5725em;
    height: 1.5em;
    font-size: 1em;
    padding: 0 1ch;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: ${pxToEm(8, 16)}rem;
      height: ${pxToEm(8, 16)}rem;
      pointer-events: none;
    }
  }

  ul {
    position: absolute;
    top: ${pxToEm(50, 16)}rem;
    z-index: 10;
    width: 100%;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    max-height: 200px;
    overflow: scroll;
    li {
      padding: 0.375em 1ch !important;
    }
  }
  ul:empty {
    display: none;
  }

  li {
    background: ${constants.FORM_BACKGROUND_COLOR};
    text-align: left;
    &:first-letter {
      text-transform: capitalize;
    }
    &.selected {
      background-color: ${constants.MAIN_BODY_COLOR};
      font-weight: bold;
    }
  }

  [disabled] {
    color: ${constants.BUTTON_INACTIVE};
    -webkit-text-fill-color: ${constants.BUTTON_INACTIVE};
    border-color: ${constants.BUTTON_INACTIVE};
    opacity: 1;
    pointer-events: none;
    & ~ label {
      color: ${constants.BUTTON_INACTIVE};
    }
    & ~ button {
      pointer-events: none;
      svg * {
        fill: ${constants.BUTTON_INACTIVE};
      }
    }
  }
`;

interface SelectProps {
  id: string;
  valuePointer?: string;
  items: { [key: string]: unknown }[];
  initialValue: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => unknown;
  onInputValueChange?: (value: string | undefined) => unknown;
  form?: FormikProps<FormikValues>;
  field?: FieldInputProps<FormikValues>;
  className?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputMode?: React.HTMLAttributes<any>["inputMode"];
  idPrefix: string;
  timeStamp: string;
  label: string;
  required?: boolean;
  onClearCallback?: () => unknown;
  noClear?: boolean;
  clearInitialValue?: boolean;
}

const Select: React.FunctionComponent<SelectProps> = ({
  valuePointer = "value",
  items = [],
  className,
  clearInitialValue,
  ...props
}) => {
  const [t] = useTranslation();
  const [state, setState] = useState({
    value: props.initialValue || "",
    id: null,
    isOpen: false,
    showFullList: false
  });

  useEffect(() => {
    props.initialValue &&
      setState({
        ...state,
        value: props.initialValue
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  useEffect(() => {
    if (clearInitialValue) {
      setState({
        ...state,
        value: ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearInitialValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any): void => {
    if (!e) return;
    if (e.id === state.id) return;
    props.onChange && props.onChange(e);
  };

  const expandFullList = (e: React.MouseEvent): void => {
    e.stopPropagation();
    (e.target as HTMLInputElement | HTMLButtonElement).focus();
    setState({ ...state, showFullList: true, isOpen: !state.isOpen });
  };

  const filterList = (): { [key: string]: unknown }[] => {
    return items.filter(item => {
      return !state.showFullList
        ? !state.value ||
            getObjectValue(item, valuePointer)
              .toLowerCase()
              .includes(`${state.value}`.toLowerCase())
        : true;
    });
  };

  const filterOnBlur = (): { [key: string]: unknown }[] => {
    const newList = items.filter(item => {
      return !state.showFullList
        ? !state.value ||
            getObjectValue(item, valuePointer)
              .toLowerCase()
              .includes(`${state.value}`.toLowerCase())
        : true;
    });
    const exactMatchedList = newList.filter(
      item => getObjectValue(item, valuePointer) === state.value
    );
    if (exactMatchedList.length) {
      return exactMatchedList;
    } else {
      return newList;
    }
  };

  const selectItem = (
    value: string
  ): ((obj: { [key: string]: string }) => boolean) => {
    if (valueExists(state.id)) return propEquals("id", state.id);
    return propEquals(valuePointer, value);
  };

  const handleSelectBlur = (): void => {
    const inputValue =
      valueExists(filterOnBlur()) &&
      valueExists(state.value) &&
      getObjectValue(filterOnBlur(), `[0][${valuePointer}]`).indexOf(
        state.value
      ) >= 0
        ? getObjectValue(filterOnBlur(), `[0][${valuePointer}]`)
        : state.value
        ? state.value
        : "";

    const itemFromList = findFirstInList(selectItem(inputValue), filterList());

    setState({ ...state, isOpen: false, value: inputValue });

    props.onInputValueChange && props.onInputValueChange(itemFromList);
    handleChange(itemFromList);
  };

  function stateReducer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentState: { [key: string]: any },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changes: { [key: string]: any }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): { [key: string]: any } {
    let inputValue, itemFromList;

    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownArrowDown:
        setState({ ...state, isOpen: true });
        return {
          ...changes
        };
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        props.onInputValueChange &&
          props.onInputValueChange(changes.inputValue);
        setState({
          ...state,
          isOpen: false,
          id: changes.selectedItem.id,
          value: changes.inputValue,
          showFullList: false
        });
        if (valueExists(props.form)) {
          props.form?.setValues({
            ...props.form?.values,
            [props.field?.name || ""]: changes.selectedItem
          });
        }
        return {
          ...changes
        };
      case Downshift.stateChangeTypes.changeInput:
        setState({
          ...state,
          value: changes.inputValue,
          showFullList: false,
          isOpen: true,
          id: null
        });

        props.onInputValueChange &&
          props.onInputValueChange(changes.inputValue);

        return {
          ...changes,
          isOpen: true,
          inputValue: state.value,
          highlightedIndex: 0
        };

      case Downshift.stateChangeTypes.blurInput:
        inputValue =
          valueExists(filterOnBlur()) &&
          valueExists(state.value) &&
          getObjectValue(filterOnBlur(), `[0][${valuePointer}]`)
            .toLowerCase()
            .indexOf(`${state.value}`.toLowerCase()) >= 0
            ? getObjectValue(filterOnBlur(), `[0][${valuePointer}]`)
            : "";

        itemFromList = findFirstInList(selectItem(inputValue), filterList());

        setState({ ...state, value: inputValue, isOpen: false });

        if (valueExists(props.form)) {
          props.form?.setValues({
            ...props.form?.values,
            inputValue,
            [props.field?.name || ""]: itemFromList
          });
        }

        props.onInputValueChange && props.onInputValueChange(itemFromList);
        handleChange(itemFromList);

        return {
          ...changes,
          inputValue
        };

      default:
        return changes;
    }
  }

  return (
    <Downshift
      selectedItem={state.value ? state.value : ""}
      onChange={handleChange}
      itemToString={(item: { [key: string]: string }): string =>
        item ? getObjectValue(item, valuePointer) : ""
      }
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getRootProps,
        getMenuProps,
        highlightedIndex
      }): ReactElement => {
        return (
          <SelectView
            {...getRootProps()}
            className={className}
            onBlur={handleSelectBlur}
          >
            <TextInput
              {...getInputProps()}
              id={props.id}
              onClick={expandFullList}
              inputMode={props.inputMode}
              idPrefix={props.idPrefix}
              timeStamp={props.timeStamp}
              label={props.label}
              form={props.form}
              name={props.field?.name}
              className={getInputProps().className}
              value={capitalizeString(`${state.value}`, true)}
              disabled={props.disabled}
              required={props.required}
            >
              <button
                tabIndex={-1}
                className="expandButton"
                type="button"
                onBlur={handleSelectBlur}
                onClick={expandFullList}
                disabled={props.disabled}
              >
                <Icon aria-hidden="true" name="triangle_down" />
                <span className="ric-hidden">{t("general.expand_list")}</span>
              </button>
            </TextInput>
            <label id={`${props.id}-label`} className="ric-hidden">
              {props.label}
            </label>
            {!props.disabled && !props.noClear && (
              <button
                type="button"
                className="cfl clear-button"
                onClick={(): void => {
                  handleChange(undefined);
                  props.onClearCallback && props.onClearCallback();
                  props.onInputValueChange &&
                    props.onInputValueChange(undefined);
                  setState({
                    value: "",
                    id: null,
                    isOpen: false,
                    showFullList: false
                  });
                }}
              >
                {t("general.clear")}
              </button>
            )}
            <ul {...getMenuProps()}>
              {state.isOpen && valueExists(filterList())
                ? filterList().map(
                    (item: { [key: string]: unknown }, index: number) => (
                      //eslint-disable-next-line react/jsx-key
                      <li
                        {...getItemProps({
                          key: `${getObjectValue(item, valuePointer)}-${
                            item.id
                          }`,
                          index,
                          item,
                          className:
                            highlightedIndex === index ? "selected" : undefined
                        })}
                      >
                        {getObjectValue(item, valuePointer)}
                      </li>
                    )
                  )
                : null}
            </ul>
          </SelectView>
        );
      }}
    </Downshift>
  );
};

export default Select;
