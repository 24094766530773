import { PageComponentProps } from "../../interfaces/common";
import styled from "styled-components";
import constants from "../_globalStyles/constants";
import React from "react";
import { Field } from "formik";
import ConnectedRadioButton from "../molecules/ConnecterRadioButton";
import { capitalizeString } from "../../util/helpers";

interface RadiosListProps extends PageComponentProps {
  header?: string;
  inverseRadio?: boolean;
  items: {
    id: string;
    fieldName: string;
    value: string | number;
    label: string;
  }[];
}

const RadiosListView = styled.div`
  h3 {
    margin: 1.5em 0;
    font-size: 1rem;
    color: ${constants.SWOOSH_BRAND_COLOR};
  }

  > div {
    padding: 0.75em 0;
    border-top: 1px solid ${constants.LIGHTER_SWOOSH_COLOR};

    &:last-child {
      border-bottom: 1px solid ${constants.LIGHTER_SWOOSH_COLOR};
    }
  }
`;

const RadiosList: React.FunctionComponent<RadiosListProps> = ({
  header,
  items,
  idPrefix,
  timeStamp,
  inverseRadio,
  ...props
}) => {
  return (
    <RadiosListView {...props}>
      {header && <h3 className="cfl">{header}</h3>}
      {items.map((item, i) => (
        <Field
          name={item.fieldName}
          component={ConnectedRadioButton}
          inverseRadio={inverseRadio}
          key={item.label + i}
          value={item.id}
          label={capitalizeString(item.label, true)}
          id={`${idPrefix}-role-${item.label}-${timeStamp}`}
          className="toggle-employee-level"
          required
        />
      ))}
    </RadiosListView>
  );
};

export default RadiosList;
