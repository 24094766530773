import React from "react";
import styled from "styled-components";
import ALink from "../atoms/ALink";
import { PageComponentProps } from "../../interfaces/common";

const PhoneAndMessageButtonsView = styled.div`
  display: flex;
`;

interface PhoneAndMessageButtonsProps extends PageComponentProps {
  buttons: any[];
}

const PhoneAndMessageButtons: React.FunctionComponent<PhoneAndMessageButtonsProps> = props => {
  return (
    <PhoneAndMessageButtonsView>
      {props.buttons.map(item => {
        return (
          <ALink
            id={`${props.idPrefix}-${item.name}-${props.timeStamp}`}
            key={item.name}
            href={item.href}
            name={item.name}
            aria-hidden="true"
            iconName={item.name}
          />
        );
      })}
    </PhoneAndMessageButtonsView>
  );
};

export default PhoneAndMessageButtons;
