import React from "react";
import styled from "styled-components";
import constants from "../_globalStyles/constants";
import { pxToEm } from "../../util/helpers";

const ProfilePictureView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${pxToEm(76, 16)}rem;
  height: ${pxToEm(76, 16)}rem;
  background-color: ${constants.PROFILE_PICTURE_COLOR};
  background-image: ${(props: ProfilePictureProps): string | null =>
    props.image ? `url(${props.image})` : null};
  background-size: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 3px ${constants.BOX_SHADOW_COLOR};
  font-size: ${pxToEm(50, 16)}rem;
  color: ${constants.PROFILE_PLACEHOLDER_COLOR};
  letter-spacing: 0;
  text-transform: uppercase;
`;

interface ProfilePictureProps {
  image?: string;
  placeholder?: string;
  className?: string;
}

const ProfilePicture: React.FunctionComponent<ProfilePictureProps> = props => {
  return (
    <ProfilePictureView aria-hidden="true" {...props}>
      {!props.image && props.placeholder}
    </ProfilePictureView>
  );
};

export default ProfilePicture;
