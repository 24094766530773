import * as React from "react";
import { useEffect } from "react";
import i18next from "../../i18n";
import Button from "../atoms/Button";
import { getObjectValue, isEqual, valueExists } from "../../util/helpers";
import {
  FormikProps,
  FormikValues,
  Formik,
  FormikHelpers,
  FormikErrors
} from "formik";
import Footer from "../molecules/Footer";

interface FormProps {
  onCancel?: (e: MouseEvent) => void;
  saveText?: string;
  cancelText?: string;
  idPrefix: string;
  timeStamp: string;
  children: React.ReactNode;
  className?: string;
  requiredValues?: string[];
  hideButtons?: boolean;
  noCancel?: boolean;
  initialValues: FormikValues;
  onChange?: (e: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any, form: FormikHelpers<FormikValues>) => void;
  errors?: FormikErrors<FormikValues>;
  prefilled?: boolean;
  withFooter?: boolean;
}

const Form: React.FunctionComponent<FormProps> = ({
  onCancel,
  saveText = i18next.t("general.save"),
  cancelText = i18next.t("general.cancel"),
  idPrefix,
  timeStamp,
  children,
  className,
  requiredValues = [],
  hideButtons,
  noCancel,
  prefilled,
  withFooter,
  ...props
}) => {
  const FormComponent: React.FunctionComponent<FormikProps<
    FormikValues
  >> = formProps => {
    const requireFieldsFilled = (): boolean => {
      if (valueExists(requiredValues) && !valueExists(formProps.values)) {
        return false;
      }
      let missingRequired: string[] = [];
      const parseOptionals = (
        optionals: string[],
        missingOptional: string[]
      ): void => {
        optionals.forEach(field => {
          if (!formProps.values[field.trim()]) {
            missingOptional.push(field.trim());
          }
        });
      };
      requiredValues.forEach(value => {
        if (!value) {
          return false;
        }
        if (value.indexOf("||") >= 0) {
          const optionalFields = value.split("||") || [];
          const missingOptional: string[] = [];
          if (optionalFields.length <= 0) return;
          if (optionalFields[1].indexOf(",") >= 0) {
            const optionalBulk = optionalFields[1].split(",");
            parseOptionals([optionalFields[0]], missingOptional);
            parseOptionals(optionalBulk, missingOptional);
          } else {
            parseOptionals(optionalFields, missingOptional);
          }
          if (missingOptional.length === optionalFields.length) {
            missingRequired = [...missingRequired, ...missingOptional];
          }
        } else if (value.indexOf("&&") >= 0) {
          let preconditionMet = true;
          if (value.indexOf("~") >= 0) {
            const comparables = value.split("~ ")[1].split(" is ") || [];
            if (formProps.values[comparables[0]] !== comparables[1]) {
              preconditionMet = false;
            }
          }
          if (preconditionMet) {
            const mutuallyRequiredFields =
              value.split("~ ")[0].split("&&") || [];
            if (
              formProps.values[mutuallyRequiredFields[0].trim()] &&
              !formProps.values[mutuallyRequiredFields[1].trim()]
            ) {
              missingRequired.push(mutuallyRequiredFields[1].trim());
            }
            if (
              formProps.values[mutuallyRequiredFields[1].trim()] &&
              !formProps.values[mutuallyRequiredFields[0].trim()]
            ) {
              missingRequired.push(mutuallyRequiredFields[0].trim());
            }
          }
        } else if (
          !valueExists(getObjectValue(formProps, `values.${value.trim()}`))
        ) {
          missingRequired.push(value.trim());
        }
      });

      return missingRequired.length === 0;
    };

    const isTouched = !isEqual(formProps.values, props.initialValues);

    const enabledForSubmission =
      (!formProps.isSubmitting && isTouched && requireFieldsFilled()) ||
      (prefilled && requireFieldsFilled() && !formProps.isSubmitting);

    useEffect(() => {
      props.onChange && props.onChange(formProps.values);
    }, [formProps.values]);

    if (props.errors && !isEqual(props.errors, formProps.errors)) {
      formProps.setErrors(props.errors);
    }

    return (
      <form onSubmit={formProps.handleSubmit} className={className}>
        {React.Children.map(children, child => {
          return (
            child &&
            React.cloneElement(
              child as React.ReactElement<{
                formProps: FormikProps<FormikValues>;
              }>,
              {
                formProps
              }
            )
          );
        })}
        {!hideButtons && (
          <>
            {!withFooter && (
              <div className="buttonsContainer">
                <Button
                  className="save-button"
                  id={`${idPrefix}-save-${timeStamp}`}
                  disabled={!enabledForSubmission}
                  type="submit"
                >
                  {saveText}
                </Button>
                {!noCancel && (
                  <Button
                    secondary
                    className="cancel-button"
                    id={`${idPrefix}-cancel-${timeStamp}`}
                    type="button"
                    onClick={onCancel}
                  >
                    {cancelText}
                  </Button>
                )}
              </div>
            )}
            {withFooter && (
              <Footer>
                <div className="buttonsContainer">
                  <Button
                    className="save-button"
                    id={`${idPrefix}-save-${timeStamp}`}
                    disabled={!enabledForSubmission}
                    type="submit"
                  >
                    {saveText}
                  </Button>
                  {!noCancel && (
                    <Button
                      secondary
                      className="cancel-button"
                      id={`${idPrefix}-cancel-${timeStamp}`}
                      type="button"
                      onClick={onCancel}
                    >
                      {cancelText}
                    </Button>
                  )}
                </div>
              </Footer>
            )}
          </>
        )}
      </form>
    );
  };

  return <Formik {...props} component={FormComponent} />;
};

export default Form;
