import { getCookieByName } from "./cookieProvider";
import { difference, uniq, uniqBy } from "ramda";

export function parseNumber(
  number: string | number,
  options: { isPrice: boolean; noDecimals?: boolean } = {
    isPrice: false,
    noDecimals: false
  }
): string {
  const { isPrice, noDecimals } = options;
  let locale = "en-US";

  if (getCookieByName("locale") === "no") {
    locale = "nb-NO";
  }

  const num = Number(number);
  let parsedNum = Intl.NumberFormat(locale, {
    minimumFractionDigits: noDecimals ? 0 : isPrice ? 2 : 1,
    maximumFractionDigits: 2,
    style: isPrice ? "currency" : "decimal",
    currency: "NOK",
    currencyDisplay: "symbol"
  }).format(num);

  if (isPrice && locale === "nb-NO" && Number.isInteger(num)) {
    parsedNum = parsedNum.replace(",00", ",-");
  }

  return parsedNum;
}

export function pxToEm(px: number, fontSize: number): number {
  return px / fontSize;
}

export function deduplicateList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: (arg?: any) => any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  if (condition) {
    return uniqBy(condition, list);
  }
  return uniq(list);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function listDiff(list1: any[], list2: any[]): any[] {
  return difference(list1, list2);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseGqlErrors(error: {
  graphQLErrors: {
    extensions: {
      pointer: string;
    };
    message: string;
  }[];
}) {
  if (!error.graphQLErrors) {
    return;
  }

  const gqlErrors: {
    [key: string]: string;
  } = {};

  error.graphQLErrors.forEach(error => {
    const field = error.extensions?.pointer;
    if (field !== undefined) gqlErrors[field] = error.message;
  });
  return gqlErrors;
}

export function userRole(data: any): string {
  let role = "";
  if (data.me.assignedRoles) {
    const assignedRoles = data.me.assignedRoles.map(
      (role: any) => role.role.name
    );

    if (assignedRoles.includes("admin")) role = "admin";
    else if (assignedRoles.includes("saas_admin")) role = "saas_admin";
    else if (assignedRoles.includes("manager")) role = "manager";
    else if (assignedRoles.includes("supervisor")) role = "supervisor";
    else if (assignedRoles.includes("service_worker")) role = "service_worker";
  }

  return role;
}

export function isSaasPartner(data: any): boolean {
  return data.me?.vendor?.kind ? data.me.vendor.kind === "SAAS" : false;
}
