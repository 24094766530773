import React from "react";
import styled from "styled-components";
import { pxToEm } from "../../util/helpers";
import constants from "../_globalStyles/constants";

interface TileProps {
  id?: string;
  width?: number;
  height?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  className?: string;
}

const getWidth = (props: TileProps): string | 0 | undefined =>
  props.width && `${pxToEm(props.width, 16)}rem`;
const getHeight = (props: TileProps): string | 0 | undefined =>
  props.height && `${pxToEm(props.height, 16)}rem`;
const getPaddingTop = (props: TileProps): string | 0 | undefined =>
  props.paddingTop && `${pxToEm(props.paddingTop, 16)}rem`;
const getPaddingRight = (props: TileProps): string | 0 | undefined =>
  props.paddingRight && `${pxToEm(props.paddingRight, 16)}rem`;
const getPaddingBottom = (props: TileProps): string | 0 | undefined =>
  props.paddingBottom && `${pxToEm(props.paddingBottom, 16)}rem`;
const getPaddingLeft = (props: TileProps): string | 0 | undefined =>
  props.paddingLeft && `${pxToEm(props.paddingLeft, 16)}rem`;

const TileView = styled.div`
  width: ${getWidth};
  height: ${getHeight};
  padding-top: ${getPaddingTop};
  padding-right: ${getPaddingRight};
  padding-bottom: ${getPaddingBottom};
  padding-left: ${getPaddingLeft};
  background-color: ${constants.TILE_COLOR};
`;

const Tile: React.FunctionComponent<TileProps> = props => (
  <TileView {...props} />
);

Tile.displayName = "Tile";

export default Tile;
