import React from "react";
import styled from "styled-components";
import constants from "../_globalStyles/constants";
import NavigationItem from "../molecules/NavigationItem";
import NavigationExternalItem from "../molecules/NavigationExternalItem";
import CloseButton from "../molecules/CloseButton";
import SignoutButton from "../molecules/SignoutButton";
import { getLocationConstructor, pxToEm } from "../../util/helpers";
import SwooshSplash from "../atoms/SwooshSplash";
import SwooshLogo from "../atoms/SwooshLogo";
import { user } from "../../graphql/queries/user";
import { useQuery } from "react-apollo";
import LocaleSwitcher from "../molecules/LocaleSwitcher";
import { isSaasPartner, userRole } from "../../util/ts/helpers";

const NavigationView = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${constants.SWOOSH_BRAND_COLOR};
  background-image: url("../../assets/icons/swooshSplash.svg");
  display: flex;
  flex-direction: column;
  z-index: 999;

  .close-button {
    margin-left: auto;
    min-width: ${pxToEm(20, 16)}rem;
    height: 100%;
    padding: 1ch;
    margin-top: 0.375em;
  }

  .nav-wrap {
    margin-top: ${pxToEm(68, 16)}rem;

    max-height: calc(100vh - 250px);
    overflow: auto;
  }

  .swoosh-splash {
    position: absolute;
    width: ${pxToEm(375, 16)}rem;
    height: ${pxToEm(185, 16)}rem;
  }

  .swoosh-logo {
    position: absolute;
    height: ${pxToEm(34, 16)}rem;
    width: ${pxToEm(86, 16)}rem;
    left: ${pxToEm(20, 16)}rem;
    top: ${pxToEm(12, 16)}rem;
  }

  .sign-out-and-locale {
    margin: auto auto ${pxToEm(52, 16)}rem auto;
    text-align: center;
  }

  .locale-switcher {
    display: inline-block;
    margin-bottom: 0.375em;
  }

  .sign-out-button {
    width: auto;
    height: ${pxToEm(40, 16)}rem;
    display: block;
  }
`;

interface NavigationProps {
  onClose: () => void;
}

const Navigation: React.FunctionComponent<NavigationProps> = ({
  onClose,
  ...props
}) => {
  const { loading, data } = useQuery(user);

  const dataMe = data;

  if (loading) return null;

  const role = userRole(dataMe);

  const saasPartner = isSaasPartner(dataMe);

  const allowedItems = saasPartner
    ? [
        { location: "/", resource: "home" },
        {
          resource: "dashboard_contracts",
          location: "/contracts"
        },
        {
          resource: "dashboard_customers",
          location: "/customers"
        },
        {
          resource: "profile",
          location: "/profile"
        },
        {
          resource: "business",
          location: "/business"
        }
      ]
    : [{ location: "/", resource: "home" }].concat(
        getLocationConstructor(dataMe.me.permissions, role)
      );

  const handleClick = () => {
    onClose();
  };

  return (
    <NavigationView>
      <SwooshSplash
        aria-hidden="true"
        className="swoosh-splash"
        positionLeft={-78}
        positionTop={-115}
      />
      <SwooshLogo aria-hidden="true" className="swoosh-logo" />
      <CloseButton
        className="close-button"
        onClick={handleClick}
        tabIndex={0}
      />

      <div className="nav-wrap">
        {allowedItems.map((item: any, i: number) => (
          <NavigationItem
            id={`t-navigation-menu-link-to-${item.resource}-13032019`}
            key={item.resource + i}
            name={item.resource}
            location={item.location}
            onClose={onClose}
          />
        ))}
        {!saasPartner && (
          <>
            <NavigationItem
              id={`t-navigation-menu-link-to-educational-videos-13032019`}
              key="educational-videos"
              name="video"
              location="/educational-videos"
              onClose={onClose}
            />
            <NavigationExternalItem
              id={`t-navigation-menu-link-to-translator-13032019`}
              key="translation"
              name="translation"
              onClose={onClose}
              iosApp="googletranslate://"
              iosLink="https://apps.apple.com/us/app/google-translate/id414706506"
              androidApp="https://www.google.com/url?sa=t&source=web&rct=j&url=https://translate.google.com/"
              androidLink="https://play.google.com/store/apps/details?id=com.google.android.apps.translate"
              webLink="https://translate.google.com"
            />
          </>
        )}
      </div>
      <div className="sign-out-and-locale">
        <LocaleSwitcher />
        <SignoutButton
          className="sign-out-button"
          id={`t-navigation-menu-sign-out-button-13032019`}
        />
      </div>
    </NavigationView>
  );
};

export default Navigation;
