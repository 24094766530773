import gql from "graphql-tag";

const removeContractEquipment = gql`
  mutation RemoveContractEquipmentData($contractEquipmentId: ID!) {
    removeContractEquipment(contractEquipmentId: $contractEquipmentId) {
      id
    }
  }
`;

export default removeContractEquipment;
