import React from "react";
import styled from "styled-components";
import Button, { ButtonProps } from "../atoms/Button";
import Icon from "../atoms/Icon";
import { pxToEm } from "../../util/helpers";
import { useTranslation } from "react-i18next";

const CloseButtonView = styled(Button as any)`
  width: ${pxToEm(50, 16)}rem;
  max-height: ${pxToEm(50, 16)}rem;
`;

const CloseButton: React.FunctionComponent<ButtonProps> = ({
  className,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent): void => {
    props.onClick && props.onClick(e);
  };

  const [t] = useTranslation();

  return (
    <CloseButtonView
      className={className}
      onClick={handleClick}
      transparent
      {...props}
    >
      <Icon aria-hidden="true" name="cross" />
      <span className="ric-hidden">{t("general.close")}</span>
    </CloseButtonView>
  );
};

export default CloseButton;
