import gql from "graphql-tag";

const userIds = gql`
  query MeData {
    me {
      ... on User {
        id
        vendor {
          id
          name
          kind
          organization {
            id
            vatin
            mainEmail
            invoiceEmail
            senderEmail
            phone
            website
            senderEmailEnabled
          }
          logo
          invoiceAddress {
            id
            line1
            line2
            line3
            postcode
          }
          visitAddress {
            id
            line1
            line2
            line3
            postcode
          }
          employees {
            id
            hseCardPhoto
            person {
              id
              firstName
              lastName
              phone
              address {
                id
                postcode
                line1
                line2
              }
            }
            accountNo
          }
        }
        employee {
          id
        }
        person {
          id
        }
        assignedRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const myPartner = gql`
  query MyPartnerData {
    me {
      ... on User {
        id
        vendor {
          id
          kind
          name
        }
        assignedRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const userLocale = gql`
  query UserLocaleData {
    me {
      ... on User {
        id
        person {
          id
          locale
        }
      }
    }
  }
`;

const user = gql`
  query UserMeData {
    me {
      ... on User {
        id
        permissions {
          resource
          action
        }
        person {
          id
          firstName
          lastName
          phone
          email
          address {
            id
            postcode
            line1
            line2
          }
        }
        vendor {
          id
          kind
        }
        employee {
          id
        }
        assignedRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const userRole = gql`
  query UserRoleData {
    me {
      ... on User {
        id
        assignedRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const userInfo = gql`
  query UserInfoData {
    me {
      ... on User {
        id
        person {
          id
          firstName
          lastName
          phone
          email
          address {
            id
            postcode
            line1
            line2
          }
        }
        employee {
          id
          accountNo
          role
          hseCardPhoto
          hseCardExpirationDate
          hseCardUploadedAt
          startDate
        }
        vendor {
          id
        }
        assignedRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export { userIds, myPartner, userLocale, user, userRole, userInfo };
