import React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";
import { pxToEm } from "../../util/helpers";

interface HamburgerProps {
  onClick: () => void;
  id: string;
  tabIndex?: number;
}

const Hamburger: React.FunctionComponent<HamburgerProps> = ({
  onClick,
  ...props
}) => {
  const handleClick = () => {
    onClick && onClick();
  };

  const [t] = useTranslation();

  const HamburgerView = styled(Button as any)`
    svg {
      width: ${pxToEm(24, 16)}rem;
      height: ${pxToEm(24, 16)}rem;
    }
  `;

  return (
    <HamburgerView onClick={handleClick} transparent {...props}>
      <Icon aria-hidden="true" name="hamburger" />
      <span className="ric-hidden">{t("general.open_menu")}</span>
    </HamburgerView>
  );
};

export default Hamburger;
