import validator from "validator";
import {
  prop,
  path,
  assocPath,
  isEmpty,
  isNil,
  equals,
  find,
  last,
  pathEq,
  propEq,
  uniq,
  uniqBy,
  filter,
  forEachObjIndexed,
  difference,
  keys,
  without
} from "ramda";
import {
  format as formatDate,
  isThisWeek,
  isFuture,
  getISOWeek,
  getYear,
  getMonth,
  endOfISOWeek,
  startOfISOWeek,
  getHours,
  getMinutes,
  addWeeks,
  subWeeks,
  parseISO,
  closestTo,
  setHours,
  setMinutes,
  setDay,
  isThisMonth,
  isPast,
  getISOWeeksInYear,
  addDays,
  startOfISOWeekYear
} from "date-fns";
import imageCompression from "browser-image-compression";
import { setCookie, deleteCookie, getCookieByName } from "./cookieProvider";
import history from "./history";
import i18next from "../i18n";
import client from "./apolloClient";
import * as loadImage from "blueimp-load-image";
import { isUndefined as _isUndefined } from "lodash/lang";
import { toPath } from "lodash/fp/_util";
import { debounce as _debounce } from "lodash/function";
import { capitalize, padStart } from "lodash/string";
import { tail } from "lodash/array";

import noDateLocale from "date-fns/locale/nb";
import enDateLocale from "date-fns/locale/en-US";

import { WEEK_DAYS } from "./constants";

export function parseNumber(
  number,
  options = { isPrice: false, noDecimals: false }
) {
  const { isPrice, noDecimals } = options;
  let locale = "en-US";

  if (getCookieByName("locale") === "no") {
    locale = "nb-NO";
  }

  const num = Number(number);
  return Intl.NumberFormat(locale, {
    minimumFractionDigits: noDecimals || isPrice ? 0 : 1,
    maximumFractionDigits: 2,
    style: isPrice ? "currency" : "decimal",
    currency: "NOK",
    currencyDisplay: "symbol"
  }).format(num);
}

export function getObjectValue(obj, value) {
  const valuePath = toPath(value);
  if (valuePath.length > 1) {
    return path(valuePath, obj);
  }
  return prop(value, obj);
}

export function setObjectValue(obj, path, value) {
  const valuePath = toPath(path);
  return assocPath(valuePath, value, obj);
}

export function pxToEm(px, fontSize) {
  return px / fontSize;
}

export function heightPercentsToPx(height) {
  const vh = window.innerHeight / 100;

  return `${pxToEm(vh * height, 16)}rem`;
}

export function isEmail(value) {
  if (validator.isEmail(value)) return i18next.t("validations.email_valid");
}

export function isUrl(value) {
  if (validator.isURL(value)) return i18next.t("validations.url_valid");
}

export function isPhone(value) {
  if (
    validator.isMobilePhone(value) &&
    validator.isLength(value, { min: 8, max: 8 })
  )
    return i18next.t("validations.phone_valid");
}

export function isPostCode(value) {
  if (validator.isPostalCode(value, ["NO"]))
    return i18next.t("validations.postcode_valid");
}

export function isVatin(value) {
  if (
    validator.isLength(value, { min: 9, max: 9 }) &&
    validator.isNumeric(value)
  )
    return i18next.t("validations.vatin_valid");
}

export function isAccountNo(value) {
  if (
    validator.isLength(value, { min: 11, max: 11 }) &&
    validator.isNumeric(value)
  )
    return i18next.t("validations.accountno_valid");
}

export function passwordValid(value) {
  const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (pattern.test(value)) return i18next.t("validations.password_valid");
}

export function valueExists(value) {
  return !(isEmpty(value) || isNil(value));
}

export function hideValidatedFormFields(formElement, formik) {
  if (valueExists(getObjectValue(formik, "current.state.errors"))) {
    formElement.current
      .querySelectorAll(".text-input-wrapper")
      .forEach(element => {
        element.style.display = "none";

        if (
          Object.keys(formik.current.state.errors).indexOf(
            element.querySelector("input").name
          ) >= 0
        ) {
          element.style.display = "block";
          element.classList += " error";
        }
      });

    formElement.current.querySelectorAll("fieldset").forEach(fieldset => {
      if (!fieldset.querySelectorAll(".text-input-wrapper.error").length)
        fieldset.style.display = "none";
    });
  }
}

export function showAllFormFields(formElement) {
  formElement.current.querySelectorAll("fieldset").forEach(fieldset => {
    fieldset.style.display = "block";
  });
}

export function debounce(fn, time) {
  _debounce(fn, time)();
}

export function isUndefined(value) {
  return _isUndefined(value);
}

export function signIn(value) {
  const days = 30;
  const date = days * 3600 * 24;

  deleteCookie("token");
  setCookie("token", value, { expires: date, path: "/" });
  history.push("/");
}

export function setLocale(locale) {
  setCookie("locale", locale, { path: "/" });
}

export function signOut() {
  client.clearStore();

  deleteCookie("token");
  history.push("/login");
}

export function hexToRGBA(color, opacity) {
  const r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16);

  if (!opacity) return `rgba(${r},${g},${b})`;

  return `rgba(${r},${g},${b},${opacity})`;
}

export function groupAlphabetical(array, keyName, objName) {
  return Object.values(
    array.reduce((r, e) => {
      let group = [];
      if (objName) {
        group = e[objName][keyName][0].toUpperCase();
      } else {
        const reg = new RegExp(/^[0-9a-zA-ZæøåÆØÅ]+$/);
        const getGroupName = (keyName, index) => {
          if (index >= keyName.length) return "0";
          if (reg.test(keyName[index])) return keyName[index];
          return getGroupName(keyName, index + 1);
        };
        const groupName = getGroupName(e[keyName], 0);
        group = isNaN(parseInt(groupName)) ? groupName.toUpperCase() : "0-9";
      }
      if (!r[group]) r[group] = { group, children: [e] };
      else r[group].children.push(e);
      return r;
    }, {})
  ).sort((a, b) => {
    // Hardcoded Norwegian alphabet sorting (Unicode sort not working for Norwegian alphabet for symbols (Æ	Ø	Å))
    const collator = new Intl.Collator("nn");
    return collator.compare(a.group, b.group);
  });
}
export function groupList(array, keyName, objName) {
  return Object.values(
    array.reduce((r, e) => {
      let group = [];
      if (objName) {
        group = e[objName][keyName][0].toUpperCase();
      } else {
        const reg = new RegExp(/^[0-9a-zA-ZæøåÆØÅ]+$/);
        const getGroupName = (keyName, index) => {
          if (index >= keyName.length) return "0";
          if (reg.test(keyName[index])) return keyName[index];
          return getGroupName(keyName, index + 1);
        };
        const groupName = getGroupName(e[keyName], 0);
        group = isNaN(parseInt(groupName)) ? groupName.toUpperCase() : "0-9";
      }
      if (!r[group]) r[group] = { group, children: [e] };
      else r[group].children.push(e);
      return r;
    }, {})
  );
}

export function isEqual(val1, val2) {
  return equals(val1, val2);
}

export function isAccessDenied(errors) {
  if (!valueExists(errors)) return false;
  return isNil(find(pathEq(["extensions", "code"], "ACCESS_DENIED"))(errors));
}

export function deduplicateList(list, condition) {
  if (condition) {
    return uniqBy(condition, list);
  }
  return uniq(list);
}

export function findFirstInList(condition, list) {
  return find(condition, list);
}

export function getLastElement(list) {
  return last(list);
}

export function propEquals(prop, value) {
  return propEq(prop, value);
}

export function filterList(condition, list) {
  return filter(condition, list);
}

export function findUrlQuery(query, needle) {
  const haystack = query.substr(1);
  let found = null;
  haystack.split("&").forEach(function(part) {
    const item = part.split("=");
    if (item[0] === needle) {
      found = item[1];
    }
  });
  return found;
}

export function getWeekNumber(date) {
  let locale = noDateLocale;

  if (getCookieByName("locale") === "en") {
    locale = enDateLocale;
  }

  return getISOWeek(date, {
    locale,
    weekStartsOn: 1
  });
}

export function getYearForJobs(date) {
  const month = getMonth(date);

  if (getWeekNumber(new Date()) === 1 && month === 11) {
    return getYear(date) + 1;
  } else {
    return getYear(date);
  }
}

export function getDate(date) {
  let locale = noDateLocale;

  if (getCookieByName("locale") === "en") {
    locale = enDateLocale;
  }

  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else if (typeof date === "string") {
    parsedDate = new Date(date.replace(/-/g, "/"));
  } else {
    parsedDate = new Date(date);
  }

  return {
    ddmmmyyy: formatDate(parsedDate, "dd MMMM yyyy", { locale }),
    ddmmyy: formatDate(parsedDate, "dd.MM.yyyy", { locale }),
    full: formatDate(parsedDate, "dd MMMM yyyy HH:mm", { locale }),
    iso: formatDate(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { locale }),
    dash: formatDate(parsedDate, "yyyy-MM-dd", { locale }),
    mmmmdd: formatDate(parsedDate, "MMMM dd", { locale }),
    mmmdd: formatDate(parsedDate, "MMM dd", { locale }),
    mmyy: formatDate(parsedDate, "MM.yyyy", { locale }),
    yyyymmdd: formatDate(parsedDate, "yyyy-MM-dd", { locale }),
    ddmmyyyy: formatDate(parsedDate, "dd-MM-yyyy", { locale }),
    ddddmmmmdd: formatDate(parsedDate, "EEEE MMMM dd", { locale }),
    dddd: formatDate(parsedDate, "EEEE", { enDateLocale }),
    DD: formatDate(parsedDate, "dd", { enDateLocale }),
    MM: formatDate(parsedDate, "MM", { enDateLocale }),
    YYYY: formatDate(parsedDate, "yyyy", { enDateLocale }),
    ms: formatDate(parsedDate, "x"),
    week: formatDate(parsedDate, "w"),
    day: formatDate(parsedDate, "EEEE", { locale }),
    d: formatDate(parsedDate, "d", { locale }),
    mmmmyyyy: formatDate(parsedDate, "MMMM yyyy", { locale }),
    mmmm: formatDate(parsedDate, "MMMM", { locale })
  };
}

export function getLocationConstructor(permissions, role) {
  const pagePermissions = [
    {
      id: 1,
      resource: "dashboard_deviations",
      action: "read",
      location: "/deviations"
    },
    {
      id: 2,
      resource: "dashboard_messages",
      action: "read",
      location: "/messages"
    },
    {
      id: 3,
      resource: "dashboard_jobs",
      action: "read",
      location: `/jobs/${getYearForJobs(new Date())}/${getWeekNumber(
        new Date()
      )}/${getDate(new Date()).yyyymmdd}`
    },
    {
      id: 4,
      resource: "dashboard_contracts",
      action: "read",
      location: "/contracts"
    },
    {
      id: 5,
      resource: "dashboard_customers",
      action: "read",
      location: "/customers"
    },
    {
      id: 6,
      resource: "dashboard_employees",
      action: "read",
      location: "/employees"
    },
    {
      id: 9,
      resource: "dashboard_vendors",
      action: "read",
      location: "/vendors"
    },
    {
      id: 10,
      resource: "dashboard_nps",
      action: "read",
      location:
        role === "admin" || role === "saas_admin" ? "nps-partners" : "/nps"
    },
    {
      id: 11,
      resource: "dashboard_assignments",
      action: "read",
      location: `/partner-assignments/${getYearForJobs(
        new Date()
      )}/${getWeekNumber(new Date())}`
    },
    {
      id: 12,
      resource: "dashboard_documents",
      action: "read",
      location: "/files"
    }
  ];

  const permitedLocations = [];
  permissions.forEach(permission => {
    const matchedByResource = find(propEq("resource", permission.resource))(
      pagePermissions
    );
    if (!valueExists(matchedByResource)) return;

    const matched = find(propEq("action", permission.action))([
      matchedByResource
    ]);
    if (find(propEq("resource", "vendors"))(permitedLocations)) {
      if (permission.resource === "employees") {
        return;
      }
    }
    matched && permitedLocations.push(matched);
  });
  permitedLocations.push({
    id: 7,
    resource: "profile",
    action: "list",
    location: "/profile"
  });
  if (role === "manager") {
    permitedLocations.push({
      id: 8,
      resource: "business",
      action: "list",
      location: "/business"
    });
  }

  return permitedLocations.sort((a, b) => a.id - b.id);
}

export function getPrivilegeLevel(roles) {
  if (!roles) return "customer";

  const rolesList = roles.map(role => role.role.name);

  if (rolesList.indexOf("admin") >= 0) return "admin";
  if (rolesList.indexOf("saas_admin") >= 0) return "saas_admin";
  if (rolesList.indexOf("manager") >= 0) return "manager";
  if (rolesList.indexOf("supervisor") >= 0) return "supervisor";
  return "service_worker";
}

export function mergeDublicatesArrayOfObjects(arr, sum) {
  const result = [];
  arr.forEach(function(a) {
    if (!this[a.name]) {
      this[a.name] = {
        name: a.name,
        [sum]: 0,
        unit: getObjectValue(a, "unit")
      };
      result.push(this[a.name]);
    }
    this[a.name][sum] += a[sum];
  }, Object.create(null));
  return result;
}

export function forEachObjectKey(object, method) {
  forEachObjIndexed(method, object);
}

export function noop() {
  console.log();
}

export function listDiff(list1, list2) {
  return difference(list1, list2);
}

export function getKeys(object) {
  return keys(object);
}

export function parsePrice(price, options = {}) {
  return parseNumber(Math.ceil(price), { isPrice: true, ...options });
}

export function parseQty(qty, options = {}) {
  return parseNumber(qty, { noDecimals: true, ...options });
}

export function parseSize(size, options = {}) {
  return parseNumber(size, { ...options });
}

export function parseHours(time) {
  let timeCopy = time;
  let minutes = 0;
  let hours = 0;

  while (timeCopy >= 60) {
    timeCopy -= 60;
    hours++;
  }

  minutes = timeCopy;

  return {
    hours,
    minutes,
    string: `${hours}${i18next.t(
      "general.hours_shorthand"
    )} ${minutes}${i18next.t("general.minutes_shorthand")}`,
    minutesString: `${time}${i18next.t("general.minutes_shorthand")}`
  };
}

export function parseGqlErrors(error) {
  if (!error.graphQLErrors) {
    return;
  }

  const gqlErrors = {};
  error.graphQLErrors.forEach(error => {
    const field = getObjectValue(error, "extensions.pointer");
    if (field !== undefined) gqlErrors[field] = error.message;
  });
  return gqlErrors;
}

export function doubleDigit(number) {
  return padStart(number, 2, "0");
}

export function getServiceFrequencies(freq1, freq2, freq3, freq4) {
  const weeks = [];

  if (!freq1 && !freq2 && !freq3 && !freq4) {
    return null;
  }

  if (freq1 && freq2 && freq3 && freq4) {
    return i18next.t(`schedules.frequency.EVERY_WEEK`);
  }

  if (freq1) weeks.push(i18next.t(`general.first`));
  if (freq2) weeks.push(i18next.t(`general.second`));
  if (freq3) weeks.push(i18next.t(`general.third`));
  if (freq4) weeks.push(i18next.t(`general.fourth`));

  const len = weeks.length;
  let phrase = "";

  if (len === 1)
    return `${i18next.t("general.each")} ${weeks[0]} ${i18next.t(
      "general.week"
    )}`;

  for (let i = 0; i < len - 1; i++) {
    if (i === len - 2) {
      phrase += `${weeks[i]}`;
    } else {
      phrase += `${weeks[i]}, `;
    }
  }

  return `${i18next.t("general.each")} ${phrase} and ${
    weeks[len - 1]
  } ${i18next.t("general.week")}`;
}

export function setDateHours(date, hours) {
  return setHours(date, hours);
}

export function setDateMinutes(date, minutes) {
  return setMinutes(date, minutes);
}

export function allowedToPunchInMonth(date) {
  return (
    isThisMonth(parseISO(date), { weekStartsOn: 1 }) &&
    isPast(parseISO(date), { weekStartsOn: 1 })
  );
}

export function allowedToPunchInWeek(date) {
  return (
    isThisWeek(parseISO(date), { weekStartsOn: 1 }) &&
    isThisMonth(parseISO(date), { weekStartsOn: 1 })
  );
}

export function isFutureDate(date) {
  return isFuture(new Date(date));
}

export function weekStartEnd(weekNumber, year) {
  const yearNumber = year ? year : getYear(new Date());
  const startOfYearWeekDate = startOfISOWeekYear(
    new Date().setFullYear(yearNumber)
  );

  const d = 1 + (weekNumber - 1) * 7;

  const date = addDays(startOfYearWeekDate, d);
  return {
    start: startOfISOWeek(date),
    end: endOfISOWeek(date)
  };
}

export function increaseWeek(date, amount) {
  return addWeeks(date, amount);
}

export function decreaseWeek(date, amount) {
  return subWeeks(date, amount);
}

export function currentDayLocalized(day) {
  const dayIndex = WEEK_DAYS.indexOf(day);
  const dayNew = setDay(new Date(), dayIndex + 1);
  return getDate(dayNew).day;
}

export function capitalizeString(string, firstOnly = false) {
  const words = string.split(" ");
  if (words.length > 1) {
    if (firstOnly) {
      const toCap = words[0];
      const firstWord = toCap.charAt(0).toUpperCase() + toCap.slice(1);
      return [firstWord, ...tail(words)].join(" ");
    }
    return words.map(word => capitalize(word)).join(" ");
  }
  return words[0].charAt(0).toUpperCase() + words[0].slice(1);
}

export async function processImageInput(event, callback) {
  loadImage(
    event.target.files[0],
    function(img) {
      img.toBlob(async blob => {
        const compressedFile = await imageCompression(blob, {
          maxSizeMB: 1
        });
        const preview = img.toDataURL();
        const sizes = {
          width: img.width,
          height: img.height
        };
        callback(compressedFile, preview, sizes);
      });
    },
    { orientation: true, maxWidth: 800, canvas: true } // Options
  );
}

export async function processImage(image, callback) {
  loadImage(
    image,
    function(img) {
      img.toBlob(async blob => {
        const compressedFile = await imageCompression(blob, {
          maxSizeMB: 1
        });
        const preview = img.toDataURL();
        callback(compressedFile, preview);
      });
    },
    { orientation: true, maxWidth: 800, canvas: true } // Options
  );
}

export function getTimeFromDate(date) {
  const time = getDate(date).iso;
  return {
    hours:
      getHours(parseISO(time)) < 10
        ? `0${getHours(parseISO(time))}`
        : getHours(parseISO(time)),
    minutes:
      getMinutes(parseISO(time)) < 10
        ? `0${getMinutes(parseISO(time))}`
        : getMinutes(parseISO(time))
  };
}

export function findClosestDate(date, list) {
  return closestTo(date, list);
}

export function parseDate(date) {
  return parseISO(date);
}

export function stringifyAddress(line1, line2, line3, postcode) {
  return `${line1}${line2 ? `, ${line2}` : ""}${
    line3 ? `, ${line3}` : ""
  }, ${postcode}`;
}

export function backRouteConstructor(urlStr) {
  if (!urlStr) return;
  const byArr = urlStr.split("/");
  if (byArr.length <= 2) {
    return "/";
  }
  byArr.splice(-1);
  return byArr.join("/");
}

export function roomNameConstructor(sequentialNumber, name, type) {
  const sequence = doubleDigit(sequentialNumber);
  const roomType = getObjectValue(type, "name");
  const roomName = !name
    ? roomType
      ? i18next.t(`room.room_types.${roomType}`)
      : ""
    : name;
  const generatedName = `${sequence} ${capitalizeString(roomName, true)}`;

  return generatedName;
}

export function contractNameConstructor(data) {
  const name = data.name;
  const address = getObjectValue(data, "visitAddress.line1");

  const contractName = `${name}${address ? ` - ${address}` : ""}`;

  return contractName;
}

// Check this for room elements
export function getRoomTasks(tasks = []) {
  const roomsList = [];
  tasks.forEach(item => {
    const roomService = {
      ...item.service,
      completedStatus: item.completedStatus,
      executionId: item.taskExecutionId,
      serviceId: getObjectValue(item, "service.id"),
      taskId: item.id,
      freq1: item.freq1,
      freq2: item.freq2,
      freq3: item.freq3,
      freq4: item.freq4,
      price: item.price,
      duration: item.duration,
      visitDayId: getObjectValue(item, "visitDay.id"),
      frequency: getServiceFrequencies(
        item.freq1,
        item.freq2,
        item.freq3,
        item.freq4
      )
    };

    if (item.kind === "DEVIATION") {
      const registeredRoom = findFirstInList(
        propEquals("name", i18next.t("general.deviation_plural")),
        roomsList
      );
      if (registeredRoom) {
        registeredRoom.services.push({
          ...roomService,
          room: item.room,
          roomElements: item.roomElement,
          deviation: item.parentDeviation,
          name: item.description,
          kind: "DEVIATION"
        });
        registeredRoom.services = deduplicateList(
          registeredRoom.services,
          item => item.executionId
        );
      } else {
        roomsList.push({
          name: i18next.t("general.deviation_plural"),
          roomElements: [],
          id: item.id,
          services: [
            {
              ...roomService,
              room: item.room,
              roomElements: item.roomElement,
              deviation: item.parentDeviation,
              kind: "DEVIATION",
              name: item.description
            }
          ]
        });
      }
    } else if (item.kind === "CUSTOM") {
      const registeredRoom = findFirstInList(
        propEquals("name", i18next.t("general.custom_task_plural")),
        roomsList
      );
      if (registeredRoom) {
        registeredRoom.services.push({
          ...roomService,
          name: item.name,
          description: item.description,
          kind: "CUSTOM"
        });
        registeredRoom.services = deduplicateList(
          registeredRoom.services,
          item => item.taskId
        );
      } else {
        roomsList.push({
          name: i18next.t("general.custom_task_plural"),
          sequentialNumber: roomsList.length + 1,
          roomElements: [],
          id: item.id,
          services: [
            {
              ...roomService,
              kind: "CUSTOM",
              description: item.description,
              name: item.name
            }
          ]
        });
      }
    } else {
      const registeredRoom = findFirstInList(
        propEquals("id", getObjectValue(item, "room.id")),
        roomsList
      );

      if (registeredRoom && !item.roomElement) {
        if (roomService.price) registeredRoom.price += roomService.price;
        registeredRoom.services.push(roomService);
        registeredRoom.services.sort((a, b) => (a.name > b.name ? 0 : -1));
      } else if (item.room && !item.roomElement) {
        roomsList.push({
          ...item.room,
          price: roomService.price || 0,
          roomElements: [],
          services: [roomService]
        });
      }

      if (item.room && item.roomElement) {
        if (!registeredRoom) {
          const roomWithElement = {
            ...item.room,
            price: roomService.price || 0,
            roomElements: [item.roomElement],
            services: []
          };
          findFirstInList(
            propEquals("id", item.roomElement.id),
            roomWithElement.roomElements
          ).services = [roomService];
          findFirstInList(
            propEquals("id", item.roomElement.id),
            roomWithElement.roomElements
          ).name = item.roomElement.roomElementType.name;
          findFirstInList(
            propEquals("id", item.roomElement.id),
            roomWithElement.roomElements
          ).quantity = item.roomElement.quantity;
          findFirstInList(
            propEquals("id", item.roomElement.id),
            roomWithElement.roomElements
          ).unit = item.roomElement.unit;
          roomWithElement.services.sort((a, b) => (a.name > b.name ? 0 : -1));

          roomsList.push(roomWithElement);
        } else {
          if (roomService.price) registeredRoom.price += roomService.price;

          let firstElement = findFirstInList(
            propEquals("id", item.roomElement.id),
            registeredRoom.roomElements
          );

          if (!firstElement) {
            firstElement = {
              ...item.roomElement,
              services: []
            };
            registeredRoom.roomElements.push(firstElement);
          }

          if (valueExists(firstElement.services)) {
            const registeredElement = findFirstInList(
              propEquals("id", roomService.id),
              firstElement.services
            );
            if (!registeredElement) {
              firstElement.services.push(roomService);
            } else {
              firstElement.services[
                firstElement.services.indexOf(registeredElement)
              ] = roomService;
            }
          } else {
            firstElement.services = [roomService];
          }

          firstElement.name = getObjectValue(
            item,
            "roomElement.roomElementType.name"
          );

          firstElement.quantity = getObjectValue(item, "roomElement.quantity");

          firstElement.unit = getObjectValue(item, "roomElement.unit");

          firstElement.services.sort((a, b) => (a.name > b.name ? 0 : -1));
        }
      }
    }
  });

  const rooms = deduplicateList(roomsList, item => item.id)
    .map(room => {
      room.roomElements.sort((a, b) => (a.name > b.name ? 0 : -1));
      return room;
    })
    .sort((a, b) => a.sequentialNumber - b.sequentialNumber);

  return rooms;
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return false;
}

export function nullToString(values) {
  const newValues = { ...values };
  for (const key in newValues) {
    if (!valueExists(newValues[key])) {
      newValues[key] = "";
    }
  }

  return newValues;
}

export function parseFormErrors(e, form, values, callback) {
  const errors = parseGqlErrors(e);
  form.setValues(values);
  form.setSubmitting(false);
  form.setTouched(false);
  callback && callback(errors);
}

export function getJobDuration(tasks) {
  const duration = tasks.reduce((acc, curr) => {
    return (acc += curr);
  }, 0);

  return Math.ceil(duration);
}

export function generateWeekNumbers() {
  const weekNumbers = [];
  const date = new Date();
  const weeksNumber = getISOWeeksInYear(date);

  for (let i = 1; i <= weeksNumber; i++) {
    weekNumbers.push({
      id: i,
      week: i,
      year: getYear(date),
      value: `${i18next.t("jobs.week")} ${i}, (${
        getDate(weekStartEnd(i, getYear(date)).start).ddmmmyyy
      } - ${getDate(weekStartEnd(i, getYear(date)).end).ddmmmyyy})`
    });
  }

  return weekNumbers;
}

export function isNumber(number) {
  return typeof number === "number";
}

export function copyToClipboard(textToCopy) {
  let textArea;
  const isOS = navigator.userAgent.match(/ipad|iphone/i);

  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range, selection;

    if (isOS) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyTo() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  createTextArea(textToCopy);
  selectText();
  copyTo();
}

export function removeArrayElement(element, array) {
  return without(element, array);
}

export function parseMyFiles(myFiles) {
  const partnersSorted = [];

  myFiles.forEach(item => {
    const folders = item.folders;
    item.folders.includes(item.partner) && item.folders.shift();
    if (folders[folders.length - 1] === "") {
      folders.pop();
    }
    const file =
      folders[folders.length - 1].includes(".") && folders[folders.length - 1];

    if (file) folders.pop();

    const partner = {
      partner: item.partner,
      folders: [
        {
          items: folders,
          url: item.url,
          date: item.date,
          file
        }
      ]
    };

    const existingPartner = findFirstInList(
      propEquals("partner", item.partner),
      partnersSorted
    );

    if (existingPartner) {
      file &&
        existingPartner.folders.push({
          items: folders,
          url: item.url,
          date: item.date,
          file
        });
    } else {
      file && partnersSorted.push(partner);
    }
  });

  return partnersSorted;
}
export function groupFilesByFolders(files) {
  return files.map(item => {
    const folders = [];
    item.folders.forEach(folder => {
      const existingFolder = findFirstInList(
        propEquals("name", folder.items[0]),
        folders
      );

      if (!existingFolder) {
        if (folder.items.length === 1) {
          folders.push({
            name: folder.items[0],
            files: [{ file: folder.file, url: folder.url, date: folder.date }],
            folder: []
          });
        } else if (folder.items.length === 2) {
          folders.push({
            name: folder.items[0],
            folder: {
              name: folder.items[1],
              files: [{ file: folder.file, url: folder.url, date: folder.date }]
            }
          });
        } else if (folder.items.length >= 3) {
          folders.push({
            name: folder.items[0],
            files: [{ file: folder.file, url: folder.url, date: folder.date }],
            folder: []
          });
        }
      } else {
        if (folder.items.length === 1) {
          existingFolder.files.push({
            file: folder.file,
            url: folder.url,
            date: folder.date
          });
        } else if (folder.items.length === 2) {
          existingFolder.folder.push({
            name: folder.items[1],
            files: [{ file: folder.file, url: folder.url }]
          });
        } else if (folder.items.length >= 3) {
          existingFolder.files.push({
            file: folder.file,
            url: folder.url,
            date: folder.date
          });
        }
      }
    });

    return {
      partner: item.partner,
      folders
    };
  });
}
