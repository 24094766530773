const MAIN_LIGHT_COLOR = "#fff";
const MAIN_TINT_COLOR = "#F4F5F7";
const MAIN_SWOOSH_COLOR = "#0222b9";
const LIGHT_SWOOSH_COLOR = "#4046F4";
const LIGHTER_SWOOSH_COLOR = "#9a9df5";
const DANGER_COLOR = "#920048";
const HAPPY_COLOR = "#04651b";
const DANGER_COLOR_LIGHT = "#b3005c";
const IDLE_COLOR = "#545454";
const TEXT_DARK = "#050505";
const BOX_SHADOW_COLOR = "#444";
const BOX_SHADOW_LIGHT_COLOR = "#999";
const INPUT_OUTLINE_COLOR = "#ddd";
const TASK_RED_COLOR = "#ff615c";
const TASK_CYAN_COLOR = "#37e3ed";

//status color
const STATUS_OPEN = "#FF0000";
const STATUS_ASSIGNED = "#FFFF00";
const STATUS_DECLINED = "#D3D3D3";

const STATUS_OPEN_DARKER = "#8b0000";
const STATUS_ASSIGNED_DARKER = "#FFD800";
const STATUS_DECLINED_DARKER = "#a9a9a9";

const constants = {
  BUTTON_ACTIVE: MAIN_SWOOSH_COLOR,
  BUTTON_ACTIVE_HOVER: LIGHT_SWOOSH_COLOR,
  BUTTON_DANGER: DANGER_COLOR,
  BUTTON_DANGER_HOVER: DANGER_COLOR_LIGHT,
  BUTTON_INACTIVE: IDLE_COLOR,
  BUTTON_TEXT_PRIMARY: MAIN_LIGHT_COLOR,
  INPUT_REGULAR_BACKGROUND: MAIN_LIGHT_COLOR,
  INPUT_FILLED_BACKGROUND: MAIN_TINT_COLOR,
  INPUT_ACTIVE: MAIN_SWOOSH_COLOR,
  INPUT_DANGER: DANGER_COLOR,
  INPUT_SUCCESS: HAPPY_COLOR,
  INPUT_EMPTY_LABEL: IDLE_COLOR,
  INPUT_VALUE_COLOR: TEXT_DARK,
  TILE_COLOR: MAIN_LIGHT_COLOR,
  LOGO_COLOR: MAIN_LIGHT_COLOR,
  SPLASH_COLOR: LIGHT_SWOOSH_COLOR,
  PROFILE_PICTURE_COLOR: LIGHTER_SWOOSH_COLOR,
  PROFILE_PLACEHOLDER_COLOR: LIGHT_SWOOSH_COLOR,
  SWOOSH_BRAND_COLOR: MAIN_SWOOSH_COLOR,
  MAIN_BODY_COLOR: MAIN_TINT_COLOR,
  LINK_COLOR: MAIN_SWOOSH_COLOR,
  LINK_COLOR_LIGHT: MAIN_LIGHT_COLOR,
  TEXT_COLOR_LIGHT: MAIN_LIGHT_COLOR,
  TEXT_COLOR_DARK: TEXT_DARK,
  FORM_BACKGROUND_COLOR: MAIN_LIGHT_COLOR,
  BORDER_COLOR: BOX_SHADOW_LIGHT_COLOR,
  LIGHTER_SWOOSH_COLOR,
  MAIN_LIGHT_COLOR,
  BOX_SHADOW_COLOR,
  BOX_SHADOW_LIGHT_COLOR,
  INPUT_OUTLINE_COLOR,
  HAPPY_COLOR,
  DANGER_COLOR,
  LIGHT_SWOOSH_COLOR,
  TASK_RED_COLOR,
  TASK_CYAN_COLOR,

  //status colors
  STATUS_COLOR_OPEN: STATUS_OPEN,
  STATUS_COLOR_ASSIGNED: STATUS_ASSIGNED,
  STATUS_COLOR_SOLVED: HAPPY_COLOR,
  STATUS_COLOR_CLOSED: HAPPY_COLOR,
  STATUS_COLOR_DECLINED: STATUS_DECLINED,
  STATUS_COLOR_NOT_SEND: TEXT_DARK,

  STATUS_COLOR_OPEN_DARKER: STATUS_OPEN_DARKER,
  STATUS_COLOR_ASSIGNED_DARKER: STATUS_ASSIGNED_DARKER,
  STATUS_COLOR_SOLVED_DARKER: HAPPY_COLOR,
  STATUS_COLOR_DECLINED_DARKER: STATUS_DECLINED_DARKER
};

export default constants;
