import React from "react";
import styled from "styled-components";
import { pxToEm, signOut } from "../../util/helpers";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";

const SignoutButtonView = styled(Button as any)`
  svg {
    right: 0;
    width: 1.5em;
    height: 1.5em;
    transform: translateY(${pxToEm(7, 16)}em);
  }
`;

interface SignoutButtonProps {
  className?: string;
  color?: string;
  id: string;
}

const SignoutButton: React.FunctionComponent<SignoutButtonProps> = props => {
  const handleClick = () => {
    signOut();
  };

  const [t] = useTranslation();

  return (
    <SignoutButtonView onClick={handleClick} transparent {...props}>
      {t("general.sign_out")} <Icon name="signout" />
    </SignoutButtonView>
  );
};

export default SignoutButton;
