import React from "react";
import styled from "styled-components";
import { Button as InclusiveButton } from "react-inclusive-components";

import constants from "../_globalStyles/constants";
import { pxToEm } from "../../util/helpers";

/** Button component that is used in all our forms.  */

export interface ButtonProps {
  danger?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  color?: string;
  borderColor?: string;
  buttonName?: string;
  onClick?: (e?: React.MouseEvent) => void;
  className?: string;
  tabIndex?: number;
}

const getBackground = (props: ButtonProps): string => {
  if (props.secondary) return "transparent";
  if (props.transparent) return "transparent";
  if (props.danger) return constants.BUTTON_DANGER;
  return constants.BUTTON_ACTIVE;
};

const getTextColor = (props: ButtonProps): string => {
  if (props.color) return props.color;
  if (props.secondary && props.disabled)
    return props.color ? props.color : constants.BUTTON_INACTIVE;
  if (props.secondary) return props.color ? props.color : constants.LINK_COLOR;
  return constants.BUTTON_TEXT_PRIMARY;
};

const getHoverColor = (props: ButtonProps): string => {
  if (props.danger) return constants.BUTTON_DANGER_HOVER;
  if (props.transparent) return "transparent";
  if (props.secondary) return "transparent";
  return constants.BUTTON_ACTIVE_HOVER;
};

const getBorder = (props: ButtonProps): string => {
  if (props.secondary && props.disabled)
    return `1px solid ${
      props.color
        ? props.color
        : props.borderColor
        ? props.borderColor
        : constants.BUTTON_INACTIVE
    }`;
  if (props.secondary)
    return `1px solid ${
      props.color
        ? props.color
        : props.borderColor
        ? props.borderColor
        : constants.BUTTON_ACTIVE
    }`;
  return "none";
};

const Button = styled(
  // eslint-disable-next-line
  ({
    danger,
    transparent,
    secondary,
    type,
    color,
    timeStamp,
    borderColor,
    ...props
  }) => <InclusiveButton {...props} type={type} />
)`
  display: inline-block;
  text-align: center;
  padding: 0.75em 0;
  min-width: 8.8rem;
  border-radius: 1.5rem;
  border: ${getBorder};
  cursor: pointer;
  background-color: ${getBackground};
  color: ${getTextColor};
  font-size: ${pxToEm(14, 16)}rem;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    background-color: ${getHoverColor};
    color: ${(props: ButtonProps): string | null =>
      (props.danger && props.secondary && constants.MAIN_LIGHT_COLOR) || null};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${(props: ButtonProps): string =>
      props.transparent || props.secondary
        ? "transparent"
        : constants.BUTTON_INACTIVE};
    opacity: ${(props: ButtonProps): string | null =>
      props.transparent ? "0.5" : null};
  }

  svg {
    g,
    path {
      fill: ${getTextColor};
      stroke: ${getTextColor};
    }
  }
`;

Button.displayName = "Button";

export default Button;
