import React from "react";
import constants from "../_globalStyles/constants";
import styled from "styled-components";
import { pxToEm, isNumber } from "../../util/helpers";

const FeedbackScoreView = styled.div`
  height: ${pxToEm(28, 16)}em;
  min-width: ${pxToEm(28, 16)}em;
  border-radius: ${pxToEm(14, 16)}em;
  color: ${constants.MAIN_LIGHT_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  &.red {
    border: 1px solid ${constants.DANGER_COLOR};
    color: ${constants.DANGER_COLOR};
  }
  &.green {
    border: 1px solid ${constants.HAPPY_COLOR};
    color: ${constants.HAPPY_COLOR};
  }
  &.neutral {
    border: 1px solid ${constants.SWOOSH_BRAND_COLOR};
    color: ${constants.SWOOSH_BRAND_COLOR};
  }
`;

interface FeedbackScoreProps {
  score?: number;
  className?: string;
}

const FeedbackScore: React.FunctionComponent<FeedbackScoreProps> = ({
  score,
  className
}) => {
  const getScoreBackground = () => {
    if (isNumber(score) && score === 0) return "red";
    if (score && isNumber(score) && score >= 1 && score < 7) return "red";
    if (score && isNumber(score) && score >= 7 && score < 9) return "neutral";
    if (score && isNumber(score) && score >= 9 && score <= 10) return "green";
  };

  return (
    <FeedbackScoreView
      className={`${getScoreBackground()} ${className && className}`}
    >
      <span>{score}</span>
    </FeedbackScoreView>
  );
};

export default FeedbackScore;
