import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { TextArea as Area, LiveArea } from "react-inclusive-components";
import constants from "../_globalStyles/constants";
import { pxToEm, valueExists } from "../../util/helpers";
import { InputProps, InputState } from "../../interfaces/Input";

/** Text input component that is used in all our forms.  */

const setLabelPosition = (props: InputProps): string => {
  if (props.activated === "true") return `calc(100% - 2.25em)`;
  if (props.invalid === "true" && !props.value) return `calc(0.5625em + 27px)`;
  return "0.5625em";
};

const setBackgroundColor = (props: InputProps): string => {
  if (props.filled) return constants.INPUT_FILLED_BACKGROUND;
  return constants.INPUT_REGULAR_BACKGROUND;
};

const setLabelSize = (props: InputProps): string => {
  if (props.activated === "true") return `0.5em`;
  return "1em";
};

const setHeight = (props: InputProps): string => {
  if (props.activated === "true") return `6em`;
  return "auto";
};

const setLabelColor = (props: InputProps): string => {
  if (props.invalid === "true") return constants.INPUT_DANGER;
  if (props.valid === "true") return constants.INPUT_SUCCESS;
  if (props.activated === "true") return constants.INPUT_ACTIVE;
  return constants.INPUT_EMPTY_LABEL;
};

const setInputColor = (props: InputProps): string => {
  if (props.invalid === "true") return constants.INPUT_DANGER;
  if (props.valid === "true") return constants.INPUT_SUCCESS;
  return constants.INPUT_ACTIVE;
};

const StyledAreaWrap = styled.div`
  background-color: ${setBackgroundColor};
  position: relative;
  padding-top: 1.5em;
`;

const StyledArea = styled(({ field, value, ...props }) => {
  const handleChange = (e: MouseEvent): void => {
    field && field.onChange(e);
    props.onChange(e);
  };

  return (
    <Area
      {...props}
      {...field}
      value={value}
      onChange={handleChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );
})`
  -webkit-appearance: none;
  width: 100%;
  display: block;
  padding-left: 0;
  padding-bottom: 0.375em;
  background-color: ${setBackgroundColor};
  border: none;
  border-radius: 0;
  border-bottom: ${pxToEm(1, 16)}rem solid ${setInputColor};
  font-size: 1em;
  color: ${constants.INPUT_VALUE_COLOR};
  caret-color: ${setInputColor};
  resize: vertical;
  min-height: ${setHeight};

  & + label {
    position: absolute;
    left: 0;
    bottom: ${setLabelPosition};
    font-size: ${setLabelSize};
    color: ${setLabelColor};
    padding-bottom: 0.1875em;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:required + label:after {
    display: inline-block;
    content: "*";
  }

  & ~ .errorMessage {
    display: flex;
    padding-top: 0.75em;
    font-size: ${pxToEm(12, 16)}rem;
    color: ${constants.INPUT_DANGER};
  }

  & ~ .successMessage {
    display: flex;
    padding-top: 0.75em;
    font-size: ${pxToEm(12, 16)}rem;
    color: ${constants.INPUT_SUCCESS};
  }
  & ~ .inputHint {
    display: flex;
    padding-top: 0.75em;
    font-size: ${pxToEm(12, 16)}rem;
    color: ${constants.SWOOSH_BRAND_COLOR};
  }

  &:disabled {
    background: ${constants.MAIN_LIGHT_COLOR};
    color: ${constants.INPUT_EMPTY_LABEL};
    border-color: ${constants.INPUT_EMPTY_LABEL};
    + label {
      color: ${constants.INPUT_EMPTY_LABEL};
    }
  }
`;

const TextArea: React.FunctionComponent<InputProps> = ({
  validationMethod,
  timeStamp,
  idPrefix,
  id,
  form,
  value,
  ...props
}) => {
  const fieldName = props.field?.name;

  interface TextAreaState extends InputState {
    lines: number;
  }

  const [state, setState] = useState<TextAreaState>({
    inputValue: "",
    successMessage: "",
    errorMessage: "",
    activated: valueExists(props.field?.value),
    lines: 1
  });

  useMemo(() => {
    if (!valueExists(value)) {
      setState({
        ...state,
        activated: valueExists(props.field?.value),
        inputValue: props.field?.value || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field?.value]);

  useMemo(() => {
    if (!valueExists(props.field?.value)) {
      setState({
        ...state,
        activated: valueExists(value),
        inputValue: value || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    setState({
      ...state,
      activated: true
    });
    props.onFocus && props.onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    setState({
      ...state,
      activated: valueExists(state.inputValue)
    });
    props.onBlur && props.onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setState({
      ...state,
      successMessage:
        (validationMethod && validationMethod(e.target.value)) || "",
      errorMessage: form?.errors[`${fieldName}`],
      inputValue: e.target.value
    });
    props.onChange && props.onChange(e);
  };

  const errorMessage = fieldName && form?.errors[fieldName];

  return (
    <StyledAreaWrap
      validationMethod={validationMethod}
      timeStamp={timeStamp}
      idPrefix={idPrefix}
      id={`${id}-wrap`}
      form={form}
      value={value}
      label={props.label}
    >
      <StyledArea
        {...props}
        rows={state.activated || value || props.field?.value ? 5 : 1}
        id={`${idPrefix}-${id}-${timeStamp}`}
        activated={state.activated.toString()}
        onFocus={handleFocus}
        onBlur={handleBlur}
        invalid={(
          valueExists(errorMessage) || valueExists(props.invalid)
        ).toString()}
        valid={(
          valueExists(state.successMessage) &&
          !valueExists(errorMessage) &&
          !props.invalid
        ).toString()}
        onChange={handleChange}
        labelPosition="after"
        value={state.inputValue}
      />
      {valueExists(errorMessage) && (
        <LiveArea
          id={`${idPrefix}-${id}-error-message-${timeStamp}`}
          className="errorMessage"
        >
          {errorMessage}
        </LiveArea>
      )}
    </StyledAreaWrap>
  );
};

TextArea.displayName = "TextArea";

export default TextArea;
