import React, { useState } from "react";
import styled from "styled-components";
import ToggleButton from "../molecules/ToggleButton";
import constants from "../_globalStyles/constants";

const ToggleGroupView = styled.div`
  display: flex;

  h2 {
    font-weight: 600;
  }
  .toggleButton {
    min-width: auto;
    flex: 1;
    + .toggleButton {
      border-left: none;
    }
  }

  .single-layer[aria-pressed="true"]:not(:first-child) {
    border-left: 1px solid ${constants.MAIN_LIGHT_COLOR};
  }
`;

interface ToggleGroupProps {
  idPrefix: string;
  onClickAction: (
    id: string,
    item: { item: string; textLabel?: string; hiddenText?: string }
  ) => unknown;
  initialValues?: string[];
  className?: string;
  singleLayer?: boolean;
  currentSelected?: string | number;
  items: { item: string; textLabel?: string; hiddenText?: string }[];
}

const ToggleGroup: React.FunctionComponent<ToggleGroupProps> = ({
  idPrefix,
  onClickAction,
  initialValues,
  singleLayer,
  currentSelected,
  items,
  ...props
}) => {
  const [current, setCurrent] = useState(currentSelected);

  const handleClick = (
    id: string,
    item: { item: string; textLabel?: string; hiddenText?: string }
  ): void => {
    setCurrent(id);
    onClickAction && onClickAction(id, item);
  };

  const group = items.map((item, index) => {
    const selected = initialValues?.indexOf(item.item) !== -1;

    const pressed = singleLayer ? selected : `${index}` === `${current}`;

    return (
      <ToggleButton
        {...props}
        idPrefix={`${idPrefix}-${index}`}
        timeStamp="09092020"
        key={item.item.concat("index")}
        textLabel={item.textLabel}
        hiddenText={item.hiddenText}
        pressed={pressed}
        initialSelected={!singleLayer && selected}
        className={singleLayer ? "single-layer" : ""}
        onClick={(): void => handleClick(`${index}`, item)}
      />
    );
  });
  return <ToggleGroupView className={props.className}>{group}</ToggleGroupView>;
};

export default ToggleGroup;
