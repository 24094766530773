import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { pxToEm } from "../../util/helpers";
import BackButton from "../molecules/BackButton";
import Hamburger from "../molecules/Hamburger";
import HomeButton from "../molecules/HomeButton";
import constants from "../_globalStyles/constants";
import Navigation from "./Navigation";
import { useTranslation } from "react-i18next";

const HeaderView = styled.div`
  display: flex;
  width: 100%;
  min-height: 3em;
  background: ${constants.SWOOSH_BRAND_COLOR};
  justify-content: space-between;
  align-items: center;
  word-break: break-word;

  &.navigation-open ~ * {
    display: none;
  }

  button:not(.sign-out-button) {
    min-width: auto;
    padding: 0.375em 1ch;
    height: 1.5em;
    width: 1.5em;
    box-sizing: content-box;
  }

  h1 {
    display: inline-block;
    color: white;
    font-size: ${pxToEm(20, 16)}em;
    margin-right: auto;
    font-weight: normal;
  }
  svg {
    width: 1.5em;
    height: 1.5em;
    box-sizing: content-box;
  }

  .dashboard-link {
    margin-right: 1em;
    padding: 0.2em 1ch;
  }
`;

interface HeaderProps {
  title: string;
  backRoute?: string;
  backCallback?: () => void;
  idPrefix: string;
  timeStamp: string;
  customerView?: boolean;
  className?: string;
  customerInvite?: string;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  title,
  backRoute,
  backCallback,
  idPrefix,
  timeStamp,
  customerView,
  className,
  ...props
}) => {
  const [navigationVisible, toggleNavigation] = useState(false);
  const [t] = useTranslation();

  const handleClick = (): void => {
    toggleNavigation(true);
  };

  const handleClose = () => {
    toggleNavigation(false);
  };

  return (
    <HeaderView
      className={
        navigationVisible
          ? `navigation-open ${className}`
          : `navigation-closed ${className}`
      }
    >
      <BackButton
        backRoute={backRoute}
        buttonName={t("navigation.return_to_previous")}
        id={`${idPrefix}-back-button-${timeStamp}`}
        tabIndex={navigationVisible ? -1 : undefined}
        onClick={backCallback}
      />
      <h1
        className="cfl"
        id={`${idPrefix}-page-title-${timeStamp}`}
        tabIndex={navigationVisible ? -1 : undefined}
      >
        {title}
      </h1>
      <HomeButton
        id={`${idPrefix}-dashboard-link-${timeStamp}`}
        className="dashboard-link"
        customerInvite={props.customerInvite}
      />
      {!customerView && (
        <Hamburger
          {...props}
          onClick={handleClick}
          id={`${idPrefix}-open-menu-${timeStamp}`}
          tabIndex={navigationVisible ? -1 : undefined}
        />
      )}
      {navigationVisible ? <Navigation onClose={handleClose} /> : null}
    </HeaderView>
  );
};

export default Header;
