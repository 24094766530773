import React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import constants from "../_globalStyles/constants";
import { pxToEm } from "../../util/helpers";
import history from "../../util/history";

const DeleteButtonView = styled(Button as any)`
  min-width: ${pxToEm(32, 16)}rem;
  svg {
    margin: ${pxToEm(5, 16)}rem auto;
    width: ${pxToEm(20, 16)}rem;
    g,
    path {
      fill: transparent;
      max-width: 10px;
      stroke: ${(props): string | null =>
        props.black ? constants.TEXT_COLOR_DARK : null};
    }
  }

  .iconWrap {
    border-radius: 50%;
    background: ${(props): string =>
      props.black ? "transparent" : constants.SWOOSH_BRAND_COLOR};
    width: ${pxToEm(30, 16)}rem;
    height: ${pxToEm(30, 16)}rem;
    display: inline-block;
  }

  .text-label {
    color: ${constants.LINK_COLOR};
    font-weight: 700;
    margin-left: ${pxToEm(10, 16)}rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:disabled {
    cursor: pointer;
    background-color: transparent;
    .text-label {
      color: ${constants.BUTTON_INACTIVE};
    }
    svg {
      background: ${constants.BUTTON_INACTIVE};
    }
  }
`;

interface DeleteButtonProps {
  redirectTo?: string;
  textLabel?: string;
  noRedirect?: boolean;
  onClick: (e: MouseEvent) => void;
  hiddenText?: string;
  black?: boolean;
  className?: string;
}

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = ({
  redirectTo,
  textLabel,
  noRedirect,
  onClick,
  hiddenText,
  black,
  ...props
}) => {
  const handleClick = (e: MouseEvent): void => {
    if (!noRedirect && redirectTo) history.push(redirectTo);
    onClick && onClick(e);
  };

  return (
    <DeleteButtonView
      onClick={handleClick}
      transparent
      black={black && black.toString()}
      {...props}
      className={props.className && props.className}
    >
      <div className="iconWrap">
        <Icon aria-hidden="true" name="trash" />
      </div>
      {textLabel && <span className="text-label">{textLabel}</span>}
      {hiddenText && <span className="ric-hidden">{hiddenText}</span>}
    </DeleteButtonView>
  );
};

export default DeleteButton;
