import WebFont from "webfontloader";
import { createGlobalStyle } from "styled-components";
import { pxToEm } from "../../util/helpers";
import constants from "./constants";

const getBodyBackgroundColor = props => props.bodyColor && props.bodyColor;

WebFont.load({
  google: {
    families: ["Lato:300,400,700", "sans-serif"]
  }
});

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Lato", Helvetica;
    font-weight: normal;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }

  body {
    font-size: 100%;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    background-color: ${getBodyBackgroundColor};
    color: ${constants.TEXT_COLOR_DARK};
    display: flex;
  }

  #root {
      width: 100%;
  }

  a, a:visited {
    font-size: ${pxToEm(14, 16)}rem;
    color: ${constants.LINK_COLOR};
    text-align: center;
  }

  form {
     background-color: ${constants.FORM_BACKGROUND_COLOR};

    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      max-width: ${pxToEm(375, 16)}rem;
      margin: ${pxToEm(30, 16)}rem auto;

      button {
        display: inline-block;
        width: 45.8666667%;
      }
    }
  }

  .logo-container {
      position: relative;
    }

  .page-loader {
    margin: 50vh auto;
    width: ${pxToEm(32, 16)}rem;
    height: ${pxToEm(32, 16)}rem;
    transform: translateY(-50%);
  }

  img {
    max-width: 100%
  }

  .cfl:first-letter {
    text-transform: capitalize;
  }
`;

GlobalStyle.defaultProps = {
  bodyColor: constants.MAIN_BODY_COLOR
};

export default GlobalStyle;
