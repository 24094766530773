import { useState, useMemo, useEffect } from "react";
import {
  deduplicateList,
  findFirstInList,
  getObjectValue,
  getServiceFrequencies,
  propEquals,
  valueExists
} from "./helpers";
import { useTranslation } from "react-i18next";

export function useTimeout(callback, timeout) {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, timeout);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useFormErrors(form, errors) {
  useEffect(() => {
    if (form && form.current && errors) {
      if (errors.undefined) delete errors.undefined;
      form.current.setErrors({ ...form.current.state.errors, ...errors });
      form.current.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
}

export function useRole() {
  const [userRole, setUserRole] = useState();
  const setUserData = data => {
    if (getObjectValue(data, "me.assignedRoles")) {
      const assignedRoles = getObjectValue(data, "me.assignedRoles").map(
        role => role.role.name
      );
      if (assignedRoles.indexOf("admin") >= 0) setUserRole("admin");
      else if (assignedRoles.indexOf("saas_admin") >= 0)
        setUserRole("saas_admin");
      else if (assignedRoles.indexOf("manager") >= 0) setUserRole("manager");
      else if (assignedRoles.indexOf("supervisor") >= 0)
        setUserRole("supervisor");
      else if (assignedRoles.indexOf("service_worker") >= 0)
        setUserRole("service_worker");
    }
  };

  return [userRole, setUserData];
}

export function useTaskRooms() {
  const [rooms, setRooms] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [t] = useTranslation();

  useMemo(() => {
    const roomsList = [];
    tasks.forEach(item => {
      const roomService = {
        ...item.service,
        completedStatus: item.completedStatus,
        executionId: item.taskExecutionId,
        serviceId: getObjectValue(item, "service.id"),
        taskId: item.id,
        freq1: item.freq1,
        freq2: item.freq2,
        freq3: item.freq3,
        freq4: item.freq4,
        price: item.price,
        visitDayId: getObjectValue(item, "visitDay.id"),
        frequency: getServiceFrequencies(
          item.freq1,
          item.freq2,
          item.freq3,
          item.freq4
        )
      };

      if (item.kind === "DEVIATION") {
        const registeredRoom = findFirstInList(
          propEquals("name", t("general.deviation_plural")),
          roomsList
        );
        if (registeredRoom) {
          registeredRoom.services.push({
            ...roomService,
            room: item.room,
            roomElements: item.roomElement,
            deviation: item.parentDeviation,
            name: item.description,
            kind: "DEVIATION"
          });
          registeredRoom.services = deduplicateList(
            registeredRoom.services,
            item => item.executionId
          );
        } else {
          roomsList.push({
            name: t("general.deviation_plural"),
            roomElements: [],
            id: item.id,
            services: [
              {
                ...roomService,
                room: item.room,
                roomElements: item.roomElement,
                deviation: item.parentDeviation,
                kind: "DEVIATION",
                name: item.description
              }
            ]
          });
        }
      } else if (item.kind === "CUSTOM") {
        const registeredRoom = findFirstInList(
          propEquals("name", t("general.custom_task_plural")),
          roomsList
        );
        if (registeredRoom) {
          registeredRoom.services.push({
            ...roomService,
            name: item.name,
            description: item.description,
            kind: "CUSTOM"
          });
          registeredRoom.services = deduplicateList(
            registeredRoom.services,
            item => item.taskId
          );
        } else {
          roomsList.push({
            name: t("general.custom_task_plural"),
            sequentialNumber: roomsList.length + 1,
            roomElements: [],
            id: item.id,
            services: [
              {
                ...roomService,
                kind: "CUSTOM",
                description: item.description,
                name: item.name
              }
            ]
          });
        }
      } else {
        const registeredRoom = findFirstInList(
          propEquals("id", getObjectValue(item, "room.id")),
          roomsList
        );

        if (registeredRoom && !item.roomElement) {
          if (roomService.price) registeredRoom.price += roomService.price;
          registeredRoom.services.push(roomService);
          registeredRoom.services.sort((a, b) => (a.name > b.name ? 0 : -1));
        } else if (item.room && !item.roomElement) {
          roomsList.push({
            ...item.room,
            price: roomService.price || 0,
            roomElements: [],
            services: [roomService]
          });
        }

        if (item.room && item.roomElement) {
          if (!registeredRoom) {
            const roomWithElement = {
              ...item.room,
              price: roomService.price || 0,
              roomElements: [item.roomElement],
              services: []
            };
            findFirstInList(
              propEquals("id", item.roomElement.id),
              roomWithElement.roomElements
            ).services = [roomService];
            findFirstInList(
              propEquals("id", item.roomElement.id),
              roomWithElement.roomElements
            ).name = item.roomElement.roomElementType.name;
            findFirstInList(
              propEquals("id", item.roomElement.id),
              roomWithElement.roomElements
            ).quantity = item.roomElement.quantity;
            findFirstInList(
              propEquals("id", item.roomElement.id),
              roomWithElement.roomElements
            ).unit = item.roomElement.unit;
            roomWithElement.services.sort((a, b) => (a.name > b.name ? 0 : -1));

            roomsList.push(roomWithElement);
          } else {
            if (roomService.price) registeredRoom.price += roomService.price;

            let firstElement = findFirstInList(
              propEquals("id", item.roomElement.id),
              registeredRoom.roomElements
            );

            if (!firstElement) {
              firstElement = {
                ...item.roomElement,
                services: []
              };
              registeredRoom.roomElements.push(firstElement);
            }

            if (valueExists(firstElement.services)) {
              const registeredElement = findFirstInList(
                propEquals("id", roomService.id),
                firstElement.services
              );
              if (!registeredElement) {
                firstElement.services.push(roomService);
              } else {
                firstElement.services[
                  firstElement.services.indexOf(registeredElement)
                ] = roomService;
              }
            } else {
              firstElement.services = [roomService];
            }

            firstElement.name = getObjectValue(
              item,
              "roomElement.roomElementType.name"
            );

            firstElement.quantity = getObjectValue(
              item,
              "roomElement.quantity"
            );

            firstElement.unit = getObjectValue(item, "roomElement.unit");

            firstElement.services.sort((a, b) => (a.name > b.name ? 0 : -1));
          }
        }
      }
    });

    setRooms(
      deduplicateList(roomsList, item => item.id)
        .map(room => {
          room.roomElements.sort((a, b) => (a.name > b.name ? 0 : -1));
          return room;
        })
        .sort((a, b) => a.sequentialNumber - b.sequentialNumber)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  return [rooms, setTasks];
}
