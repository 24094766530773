import React from "react";
import TextInput from "../atoms/TextInput";
import TextArea from "../atoms/TextArea";
import FieldSet from "../_functional/FieldSet";
import { Field, FormikProps, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import Select from "../molecules/Select";
import { PageComponentProps } from "../../interfaces/common";

interface EditEquipmentProps extends PageComponentProps {
  formProps?: FormikProps<FormikValues>;
  amountFieldName: string;
  frequencyFieldName: string;
  descriptionFieldName: string;
  initialValue: string;
  items?: any;
  error?: string | null;
}

const EditEquipmentFields: React.FunctionComponent<EditEquipmentProps> = ({
  formProps,
  ...props
}) => {
  const [t] = useTranslation();

  return (
    <>
      <FieldSet>
        <div className="fields-wrap">
          <Field
            component={Select}
            idPrefix={props.idPrefix}
            timeStamp={props.timeStamp}
            name={props.frequencyFieldName}
            label={t("general.frequency")}
            items={props.items}
            initialValue={props.initialValue}
            noClear={true}
          />
          <Field
            component={TextInput}
            idPrefix={props.idPrefix}
            timeStamp={props.timeStamp}
            name={props.amountFieldName}
            label={t("general.amount")}
            type="number"
            min="0"
            max="999"
            step="1"
          />
          <Field
            component={TextArea}
            idPrefix={props.idPrefix}
            timeStamp={props.timeStamp}
            name={props.descriptionFieldName}
            label={t("general.description")}
            type="number"
            step="1"
          />
        </div>
      </FieldSet>
      {props.error && <p className="error">{props.error}</p>}
    </>
  );
};

export default EditEquipmentFields;
