import React from "react";
import styled from "styled-components";
import { Input } from "react-inclusive-components";
import RadiobuttonInactive from "../../assets/icons/glyphs/radiobutton.svg";
import RadiobuttonSelected from "../../assets/icons/glyphs/radiobutton-selected.svg";
import { pxToEm } from "../../util/helpers";
import constants from "../_globalStyles/constants";

interface RadiobuttonProps {
  icon?: string;
  pill?: boolean;
  checked?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => unknown;
  onBlur?: (e?: React.ChangeEvent<HTMLInputElement>) => unknown;
  checkedFilled?: boolean;
  inverseRadio?: boolean;
}

interface RadioStyleProps {
  pill?: boolean;
  icon?: string;
  inverseRadio?: boolean;
  checked?: boolean;
  checkedFilled?: boolean;
}

const RadiobuttonWrap = styled.div<RadioStyleProps>`
  .input-custom-element {
    display: ${props => (props.pill ? "none" : "inline-block")};
    margin: 0.25em 1ch 0.25em 0;
    margin-right: calc(${props => (props.icon ? pxToEm(24, 16) : 0)}rem + 1ch);
    width: 1rem;
    height: 1rem;
    background-image: url(${RadiobuttonInactive});
    background-size: contain;
    background-repeat: no-repeat;
    ${props =>
      props.inverseRadio &&
      `
        order: 1;
        margin-left: auto;
      `}
  }

  input:checked ~ .input-custom-element {
    background-image: url(${RadiobuttonSelected});
  }

  label {
    display: flex;
    align-content: center;
    cursor: pointer;
    position: relative;
    text-transform: initial;
    font-size: 1em;
    color: ${constants.TEXT_COLOR_DARK};

    ${props =>
      props.pill &&
      `
        border: 1px solid ${
          props.checked
            ? constants.SWOOSH_BRAND_COLOR
            : constants.BUTTON_INACTIVE
        };
        color : ${
          props.checked
            ? props.checkedFilled
              ? constants.MAIN_LIGHT_COLOR
              : constants.SWOOSH_BRAND_COLOR
            : constants.BUTTON_INACTIVE
        };
        border-radius: 1rem;
        padding: ${props.icon ? "0 1ch 0 2.5ch" : "0 1ch"};
        ${props.checkedFilled &&
          props.checked &&
          `background: ${constants.SWOOSH_BRAND_COLOR}`}
      `}
  }

  ${props =>
    props.icon &&
    `label:before {
        content: "";
        position: absolute;
        left: ${props.pill ? `0` : `${pxToEm(20, 16)}rem`};
        top: calc(50% - ${pxToEm(12, 16)}rem);
        width: ${pxToEm(24, 16)}rem;
        height: ${pxToEm(24, 16)}rem;
        background: url("${props.icon}") no-repeat;
        background-size: .75em .75em;
        background-position: center;
      }`}
`;

const Radiobutton: React.FunctionComponent<RadiobuttonProps> = ({
  icon,
  pill = false,
  checkedFilled,
  inverseRadio,
  ...props
}) => {
  return (
    <RadiobuttonWrap
      icon={icon}
      pill={pill}
      checkedFilled={checkedFilled}
      inverseRadio={inverseRadio}
      checked={props.checked}
    >
      <Input
        {...props}
        type="radio"
        custom
        labelPosition="after"
        labelClassName="checkbox-label"
      />
    </RadiobuttonWrap>
  );
};

export default Radiobutton;
