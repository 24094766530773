import React from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";
import { pxToEm } from "../../util/helpers";
import history from "../../util/history";

interface HomeButtonProps {
  customerInvite?: string;
  id: string;
  className: string;
}

const HomeButton: React.FunctionComponent<HomeButtonProps> = props => {
  const handleClick = () => {
    props.customerInvite
      ? history.push(`/customer_dashboard/${props.customerInvite}`)
      : history.push("/");
  };

  const [t] = useTranslation();

  const HomeView = styled(Button as any)`
    svg {
      width: ${pxToEm(24, 16)}rem;
      height: ${pxToEm(24, 16)}rem;
    }
  `;

  return (
    <HomeView onClick={handleClick} transparent className={props.className}>
      <Icon aria-hidden="true" name="home" />
      <span className="ric-hidden">{t("general.to_dashboard")}</span>
    </HomeView>
  );
};

export default HomeButton;
