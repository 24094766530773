import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import constants from "../_globalStyles/constants";
import { pxToEm } from "../../util/helpers";

const ALinkView = styled.a`
  display: flex;
  align-items: center;
  svg {
    width: ${pxToEm(24, 16)}rem;
  }
`;

interface ALinkProps {
  iconName?: string;
  color?: string;
  id?: string;
  href?: string;
  name?: string;
}

const ALink: React.FunctionComponent<ALinkProps> = ({
  color = constants.LINK_COLOR,
  ...props
}) => {
  return (
    <ALinkView color={color} {...props}>
      {props.children} {props.iconName && <Icon name={props.iconName} />}
    </ALinkView>
  );
};

export default ALink;
