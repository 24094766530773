import React from "react";

// Glyphs

import { ReactComponent as Eye } from "../../assets/icons/glyphs/eye.svg";
import { ReactComponent as DirectionRight } from "../../assets/icons/glyphs/directilon right.svg";
import { ReactComponent as SignOut } from "../../assets/icons/glyphs/sign-out.svg";
import { ReactComponent as Hamburger } from "../../assets/icons/glyphs/hamburger.svg";
import { ReactComponent as Home } from "../../assets/icons/glyphs/home.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/glyphs/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/glyphs/arrow_right.svg";
import { ReactComponent as Plus } from "../../assets/icons/glyphs/plus_small.svg";
import { ReactComponent as Kebab } from "../../assets/icons/glyphs/kebab.svg";
import { ReactComponent as Company } from "../../assets/icons/glyphs/company.svg";
import { ReactComponent as Close } from "../../assets/icons/glyphs/cross.svg";
import { ReactComponent as TaskComplete } from "../../assets/icons/glyphs/checked.svg";
import { ReactComponent as TaskDeviation } from "../../assets/icons/glyphs/flagged.svg";
import { ReactComponent as TaskNotCompleted } from "../../assets/icons/glyphs/crossed.svg";
import { ReactComponent as StarEmpty } from "../../assets/icons/glyphs/star_outline.svg";
import { ReactComponent as StarHalf } from "../../assets/icons/glyphs/star_half_filled.svg";
import { ReactComponent as StarFull } from "../../assets/icons/glyphs/star_filled.svg";
import { ReactComponent as User } from "../../assets/icons/glyphs/user.svg";
import { ReactComponent as Document } from "../../assets/icons/glyphs/document.svg";
import { ReactComponent as Message } from "../../assets/icons/glyphs/message.svg";
import { ReactComponent as Phone } from "../../assets/icons/glyphs/phone.svg";
import { ReactComponent as Ansatte } from "../../assets/icons/dashboard/ansatte.svg";
import { ReactComponent as Search } from "../../assets/icons/glyphs/search.svg";
import { ReactComponent as TriangleDown } from "../../assets/icons/glyphs/triangle-down.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/glyphs/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/glyphs/arrow-up.svg";
import { ReactComponent as Timesheet } from "../../assets/icons/glyphs/timesheet.svg";
import { ReactComponent as IdBadge } from "../../assets/icons/glyphs/personal_info.svg";
import { ReactComponent as Suitcase } from "../../assets/icons/glyphs/suitcase.svg";
import { ReactComponent as Room } from "../../assets/icons/glyphs/room.svg";
import { ReactComponent as Service } from "../../assets/icons/glyphs/service.svg";
import { ReactComponent as Quality } from "../../assets/icons/glyphs/quality.svg";
import { ReactComponent as Feedback } from "../../assets/icons/glyphs/feedback.svg";
import { ReactComponent as Team } from "../../assets/icons/glyphs/team.svg";
import { ReactComponent as Schedule } from "../../assets/icons/glyphs/schedule.svg";
import { ReactComponent as Edit } from "../../assets/icons/glyphs/edit.svg";
import { ReactComponent as Notification } from "../../assets/icons/glyphs/notification.svg";
import { ReactComponent as Cleaning } from "../../assets/icons/glyphs/cleaning.svg";
import { ReactComponent as Supervision } from "../../assets/icons/glyphs/supervision.svg";
import { ReactComponent as Trash } from "../../assets/icons/glyphs/trash.svg";
import { ReactComponent as Mail } from "../../assets/icons/glyphs/mail.svg";
import { ReactComponent as MapSign } from "../../assets/icons/glyphs/map-sign.svg";
import { ReactComponent as Clock } from "../../assets/icons/glyphs/clock.svg";
import { ReactComponent as Stars } from "../../assets/icons/glyphs/stars.svg";
import { ReactComponent as Manager } from "../../assets/icons/glyphs/manager.svg";
import { ReactComponent as Upload } from "../../assets/icons/glyphs/upload.svg";
import { ReactComponent as Copy } from "../../assets/icons/glyphs/copy.svg";
import { ReactComponent as Reset } from "../../assets/icons/glyphs/reset.svg";
import { ReactComponent as Floor } from "../../assets/icons/glyphs/floor.svg";
import { ReactComponent as Percent } from "../../assets/icons/glyphs/percent.svg";
import { ReactComponent as Terms } from "../../assets/icons/glyphs/terms.svg";
//types

//elements
import { ReactComponent as Armchair } from "../../assets/icons/types/elements/armchair.svg";
import { ReactComponent as BallChair } from "../../assets/icons/types/elements/ball_chair.svg";
import { ReactComponent as BalanceChair } from "../../assets/icons/types/elements/balance_chair.svg";
import { ReactComponent as BarStool } from "../../assets/icons/types/elements/bar_stool.svg";
import { ReactComponent as Desk } from "../../assets/icons/types/elements/desk.svg";
import { ReactComponent as DiningChair } from "../../assets/icons/types/elements/dining_chair.svg";
import { ReactComponent as Dustbin } from "../../assets/icons/types/elements/dustbin.svg";
import { ReactComponent as GlassWall } from "../../assets/icons/types/elements/glass_wall.svg";
import { ReactComponent as MeetingTable } from "../../assets/icons/types/elements/meeting_table.svg";
import { ReactComponent as OfficeChair } from "../../assets/icons/types/elements/office_chair.svg";
import { ReactComponent as Refrigerator } from "../../assets/icons/types/elements/refrigerator.svg";
import { ReactComponent as Sofa } from "../../assets/icons/types/elements/sofa.svg";
import { ReactComponent as Table } from "../../assets/icons/types/elements/table.svg";
import { ReactComponent as Window } from "../../assets/icons/types/elements/window.svg";
import { ReactComponent as Fridge } from "../../assets/icons/types/elements/fridge.svg";
import { ReactComponent as Dishwasher } from "../../assets/icons/types/elements/dishwasher.svg";
import { ReactComponent as FloorMats } from "../../assets/icons/types/elements/floor_mats.svg";
import { ReactComponent as CoffeMachine } from "../../assets/icons/types/elements/coffee_machine.svg";

//rooms
import { ReactComponent as ArchiveRoom } from "../../assets/icons/types/rooms/archive_room.svg";
import { ReactComponent as Bathroom } from "../../assets/icons/types/rooms/bathroom.svg";
import { ReactComponent as BroomCloset } from "../../assets/icons/types/rooms/broom_closet.svg";
import { ReactComponent as Canteen } from "../../assets/icons/types/rooms/canteen.svg";
import { ReactComponent as Cloakroom } from "../../assets/icons/types/rooms/cloakroom.svg";
import { ReactComponent as CopyRoom } from "../../assets/icons/types/rooms/copy_room.svg";
import { ReactComponent as Corridor } from "../../assets/icons/types/rooms/corridor.svg";
import { ReactComponent as Entrance } from "../../assets/icons/types/rooms/entrance.svg";
import { ReactComponent as Kitchen } from "../../assets/icons/types/rooms/kitchen.svg";
import { ReactComponent as Kitchenette } from "../../assets/icons/types/rooms/kitchenette.svg";
import { ReactComponent as MeetingRoom } from "../../assets/icons/types/rooms/meeting_room.svg";
import { ReactComponent as Office } from "../../assets/icons/types/rooms/office.svg";
import { ReactComponent as Other } from "../../assets/icons/types/rooms/other.svg";
import { ReactComponent as Reception } from "../../assets/icons/types/rooms/reception.svg";
import { ReactComponent as Sauna } from "../../assets/icons/types/rooms/sauna.svg";
import { ReactComponent as Shower } from "../../assets/icons/types/rooms/shower.svg";
import { ReactComponent as Stairs } from "../../assets/icons/types/rooms/stairs.svg";
import { ReactComponent as Staircase } from "../../assets/icons/types/rooms/staircase.svg";
import { ReactComponent as StorageRoom } from "../../assets/icons/types/rooms/storage_room.svg";
import { ReactComponent as Toilet } from "../../assets/icons/types/rooms/toilet.svg";

import { ReactComponent as BikeStorage } from "../../assets/icons/types/rooms/bike_storage.svg";
import { ReactComponent as Windbreak } from "../../assets/icons/types/rooms/windbreak.svg";
import { ReactComponent as WasteDisposal } from "../../assets/icons/types/rooms/waste_disposal.svg";
import { ReactComponent as WaitingRoom } from "../../assets/icons/types/rooms/waiting_room.svg";
import { ReactComponent as TechnicalRoom } from "../../assets/icons/types/rooms/technical_room.svg";
import { ReactComponent as Studio } from "../../assets/icons/types/rooms/studio.svg";
import { ReactComponent as StaffRoom } from "../../assets/icons/types/rooms/staff_room.svg";
import { ReactComponent as Playroom } from "../../assets/icons/types/rooms/playroom.svg";
import { ReactComponent as Nursery } from "../../assets/icons/types/rooms/nursery.svg";
import { ReactComponent as Laundry } from "../../assets/icons/types/rooms/laundry.svg";
import { ReactComponent as FireEscape } from "../../assets/icons/types/rooms/fire_escape.svg";
import { ReactComponent as Elevator } from "../../assets/icons/types/rooms/elevator.svg";
import { ReactComponent as ElRoom } from "../../assets/icons/types/rooms/el_room.svg";
import { ReactComponent as DryingRoom } from "../../assets/icons/types/rooms/drying_room.svg";
import { ReactComponent as Classroom } from "../../assets/icons/types/rooms/classroom.svg";

// Dashboard Icons

import { ReactComponent as Defect } from "../../assets/icons/dashboard/avvik.svg";
import { ReactComponent as Vendor } from "../../assets/icons/dashboard/bedrift.svg";
import { ReactComponent as Contract } from "../../assets/icons/dashboard/avtaler.svg";
import { ReactComponent as Customers } from "../../assets/icons/dashboard/kunder.svg";
import { ReactComponent as Economy } from "../../assets/icons/dashboard/økonomi.svg";
import { ReactComponent as Employees } from "../../assets/icons/dashboard/ansatte.svg";
import { ReactComponent as Profile } from "../../assets/icons/dashboard/profile.svg";
import { ReactComponent as Jobs } from "../../assets/icons/dashboard/mine_oppdrag.svg";
import { ReactComponent as Nps } from "../../assets/icons/dashboard/nps.svg";
import { ReactComponent as ScheduleDashboard } from "../../assets/icons/dashboard/schedule.svg";
import { ReactComponent as ScheduleDashboardDark } from "../../assets/icons/dashboard/schedule_dark.svg";
import { ReactComponent as Video } from "../../assets/icons/dashboard/play.svg";
import { ReactComponent as Download } from "../../assets/icons/dashboard/download.svg";
import { ReactComponent as Messages } from "../../assets/icons/dashboard/meldinger.svg";

import { ReactComponent as Filter } from "../../assets/icons/glyphs/controls.svg";
import { ReactComponent as Order } from "../../assets/icons/glyphs/swap-vertical.svg";
import { ReactComponent as CloseCross } from "../../assets/icons/glyphs/close.svg";
import { ReactComponent as GTranslate } from "../../assets/icons/gtranslate.svg";

interface IconProps {
  name: string;
  className?: string;
}

const Icon: React.FunctionComponent<IconProps> = ({ name, ...props }) => {
  switch (name) {
    case "eye":
      return <Eye {...props} />;
    case "direction-right":
      return <DirectionRight {...props} />;
    case "deviations":
      return <Defect {...props} />;
    case "vendors":
      return <Vendor {...props} />;
    case "contracts":
      return <Contract {...props} />;
    case "customers":
      return <Customers {...props} />;
    case "economy":
      return <Economy {...props} />;
    case "signout":
      return <SignOut {...props} />;
    case "hamburger":
      return <Hamburger {...props} />;
    case "arrow_left":
      return <ArrowLeft {...props} />;
    case "arrow_right":
      return <ArrowRight {...props} />;
    case "arrow_down":
      return <ArrowDown {...props} />;
    case "arrow_up":
      return <ArrowUp {...props} />;
    case "plus_small":
      return <Plus {...props} />;
    case "kebab":
      return <Kebab {...props} />;
    case "company":
      return <Company {...props} />;
    case "task-complete":
      return <TaskComplete {...props} />;
    case "task-deviation":
      return <TaskDeviation {...props} />;
    case "task-not-completed":
      return <TaskNotCompleted {...props} />;
    case "cross":
      return <Close {...props} />;
    case "star-empty":
      return <StarEmpty {...props} />;
    case "star-half":
      return <StarHalf {...props} />;
    case "star-full":
      return <StarFull {...props} />;
    case "document":
      return <Document {...props} />;
    case "user":
      return <User {...props} />;
    case "message":
      return <Message {...props} />;
    case "phone":
      return <Phone {...props} />;
    case "ansatte":
      return <Ansatte {...props} />;
    case "search":
      return <Search {...props} />;
    case "triangle_down":
      return <TriangleDown {...props} />;
    case "timesheet":
      return <Timesheet {...props} />;
    case "id_badge":
      return <IdBadge {...props} />;
    case "suitcase":
      return <Suitcase {...props} />;
    case "room":
      return <Room {...props} />;
    case "service":
      return <Service {...props} />;
    case "quality":
      return <Quality {...props} />;
    case "feedback":
      return <Feedback {...props} />;
    case "team":
      return <Team {...props} />;
    case "schedule":
      return <Schedule {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "cleaning":
      return <Cleaning {...props} />;
    case "supervision":
      return <Supervision {...props} />;
    case "trash":
      return <Trash {...props} />;
    case "armchair":
      return <Armchair {...props} />;
    case "ball_chair":
      return <BallChair {...props} />;
    case "balance_chair":
      return <BalanceChair {...props} />;
    case "bar_stool":
      return <BarStool {...props} />;
    case "desk":
      return <Desk {...props} />;
    case "dining_chair":
      return <DiningChair {...props} />;
    case "dustbin":
      return <Dustbin {...props} />;
    case "glass_wall":
      return <GlassWall {...props} />;
    case "meeting_table":
      return <MeetingTable {...props} />;
    case "office_chair":
      return <OfficeChair {...props} />;
    case "refrigerator":
      return <Refrigerator {...props} />;
    case "sofa":
      return <Sofa {...props} />;
    case "table":
      return <Table {...props} />;
    case "window":
      return <Window {...props} />;
    case "archive_room":
      return <ArchiveRoom {...props} />;
    case "bathroom":
      return <Bathroom {...props} />;
    case "broom_closet":
      return <BroomCloset {...props} />;
    case "canteen":
      return <Canteen {...props} />;
    case "cloakroom":
      return <Cloakroom {...props} />;
    case "copy_room":
      return <CopyRoom {...props} />;
    case "corridor":
      return <Corridor {...props} />;
    case "entrance":
      return <Entrance {...props} />;
    case "kitchen":
      return <Kitchen {...props} />;
    case "kitchenette":
      return <Kitchenette {...props} />;
    case "meeting_room":
      return <MeetingRoom {...props} />;
    case "office":
      return <Office {...props} />;
    case "other":
      return <Other {...props} />;
    case "reception":
      return <Reception {...props} />;
    case "sauna":
      return <Sauna {...props} />;
    case "shower":
      return <Shower {...props} />;
    case "stairs":
      return <Stairs {...props} />;
    case "staircase":
      return <Staircase {...props} />;
    case "storage_room":
      return <StorageRoom {...props} />;
    case "toilet":
      return <Toilet {...props} />;
    case "notification":
      return <Notification {...props} />;
    case "personal_info":
      return <IdBadge {...props} />;
    case "employees":
      return <Employees {...props} />;
    case "profile":
      return <Profile {...props} />;
    case "jobs":
      return <Jobs {...props} />;
    case "business":
      return <Vendor {...props} />;
    case "mail":
      return <Mail {...props} />;
    case "map-sign":
      return <MapSign {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "stars":
      return <Stars {...props} />;
    case "manager":
      return <Manager {...props} />;
    case "upload":
      return <Upload {...props} />;
    case "dishwasher":
      return <Dishwasher {...props} />;
    case "fridge":
      return <Fridge {...props} />;
    case "home":
      return <Home {...props} />;
    case "bike_storage":
      return <BikeStorage {...props} />;
    case "windbreak":
      return <Windbreak {...props} />;
    case "waste_disposal":
      return <WasteDisposal {...props} />;
    case "waiting_room":
      return <WaitingRoom {...props} />;
    case "technical_room":
      return <TechnicalRoom {...props} />;
    case "studio":
      return <Studio {...props} />;
    case "staff_room":
      return <StaffRoom {...props} />;
    case "playroom":
      return <Playroom {...props} />;
    case "nursery":
      return <Nursery {...props} />;
    case "laundry":
      return <Laundry {...props} />;
    case "floor_mat":
      return <FloorMats {...props} />;
    case "fire_escape":
      return <FireEscape {...props} />;
    case "elevator":
      return <Elevator {...props} />;
    case "el_room":
      return <ElRoom {...props} />;
    case "drying_room":
      return <DryingRoom {...props} />;
    case "classroom":
      return <Classroom {...props} />;
    case "coffee_machine":
      return <CoffeMachine {...props} />;
    case "copy":
      return <Copy {...props} />;
    // repeated icons
    case "hallway":
      return <Corridor {...props} />;
    case "toilet_room":
      return <Bathroom {...props} />;
    case "cleaning_room":
      return <BroomCloset {...props} />;
    case "basement_hallway":
      return <Corridor {...props} />;
    case "offices":
      return <Office {...props} />;
    case "dining_room":
      return <Canteen {...props} />;
    case "common_room":
      return <WaitingRoom {...props} />;
    case "reset":
      return <Reset {...props} />;
    case "site":
      return <Floor {...props} />;
    case "retail_space":
      return <Reception {...props} />;
    case "restaurant_local":
      return <Canteen {...props} />;
    case "other_common_80":
      return <Other {...props} />;
    case "other_common_600":
      return <Other {...props} />;
    case "other_common_50":
      return <Other {...props} />;
    case "other_admin":
      return <Other {...props} />;
    case "kitchen_production":
      return <Dishwasher {...props} />;
    case "cold_storage":
      return <Fridge {...props} />;
    case "cold_storage_room":
      return <Fridge {...props} />;
    case "percent":
      return <Percent {...props} />;
    case "nps":
      return <Nps {...props} />;
    case "terms":
      return <Terms {...props} />;
    case "scheduledashboard":
      return <ScheduleDashboard {...props} />;
    case "filter":
      return <Filter {...props} />;
    case "order":
      return <Order {...props} />;
    case "assignments":
      return <ScheduleDashboard {...props} />;
    case "assignments_dark":
      return <ScheduleDashboardDark {...props} />;
    case "video":
      return <Video {...props} />;
    case "documents":
      return <Download {...props} />;
    case "close-cross":
      return <CloseCross {...props} />;
    case "messages":
      return <Messages {...props} />;
    case "translation":
      return <GTranslate {...props} />;

    default:
      return null;
  }
};

export default Icon;
