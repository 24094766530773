import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { getCookieByName } from "./cookieProvider";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: "/graphql"
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookieByName("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "X-Auth-Token": token ? `Bearer ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
