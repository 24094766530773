import gql from "graphql-tag";

export default gql`
  mutation UpdateMyLocaleData($locale: PersonLocale!) {
    updateMyLocale(locale: $locale) {
      id
      locale
    }
  }
`;
