import React from "react";
import styled from "styled-components";
import history from "../../util/history";
import Button, { ButtonProps } from "../atoms/Button";
import Icon from "../atoms/Icon";
import { findUrlQuery, pxToEm } from "../../util/helpers";

const BackButtonView = styled(Button as any)`
  svg {
    width: ${pxToEm(24, 16)}rem;
    height: ${pxToEm(24, 16)}rem;
    g {
      fill: transparent;
    }
  }
`;

interface BackButtonProps extends ButtonProps {
  backRoute?: string;
  id?: string;
  tabIndex?: number;
}

const BackButton: React.FunctionComponent<BackButtonProps> = ({
  buttonName,
  backRoute,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent): void => {
    const routeInUrl = findUrlQuery(document.location.search, "from");
    if (routeInUrl) history.push(routeInUrl);
    else if (backRoute) history.push(backRoute);
    props.onClick && props.onClick(e);
  };

  return (
    <BackButtonView {...props} transparent onClick={handleClick}>
      <Icon aria-hidden="true" name="arrow_left" />
      <span className="ric-hidden">{buttonName}</span>
    </BackButtonView>
  );
};

export default BackButton;
