import gql from "graphql-tag";

const contractEquipment = gql`
  query ContractEquipmentData($contractId: ID!) {
    equipment {
      id
      name
      sortOrder
    }
    equipmentFrequencies {
      id
      name
      iso8601Interval
      sortOrder
    }
    contract(id: $contractId) {
      id
      name
      uid
      customer {
        id
        name
      }
      contractEquipment {
        id
        quantity
        description
        equipmentFrequency {
          id
          name
          iso8601Interval
          sortOrder
        }
        equipment {
          id
          name
          sortOrder
        }
      }
    }
  }
`;

export default contractEquipment;
